import React from 'react';

export const Watch = (props) => {
  const { color } = props;
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        {...props}
      >
        <path
          d="M6.35706 10.63C6.29244 10.6946 6.29244 10.7916 6.35706 10.8562C6.42168 10.9208 6.4863 10.9208 6.58323 10.8562L8.45722 9.3376C8.55415 9.36991 8.65108 9.40222 8.74801 9.40222C9.23266 9.40222 9.6527 8.98218 9.6527 8.49753C9.6527 8.17443 9.45884 7.85133 9.16804 7.68978L8.90956 4.32952C8.90956 4.23259 8.84494 4.16797 8.74801 4.16797C8.65108 4.16797 8.58646 4.23259 8.58646 4.32952L8.32798 7.68978C8.03719 7.85133 7.84333 8.17443 7.84333 8.49753C7.84333 8.59446 7.87564 8.69139 7.90795 8.78832L6.35706 10.63Z"
          fill={color}
        />
        <path
          d="M10.5889 16.0256H13.3029V15.7994C13.3029 15.5409 13.5291 15.3148 13.7876 15.3148H15.5323C15.7908 15.3148 16.017 15.5409 16.017 15.7994V16.0256H18.7311C19.5711 16.0256 20.2496 15.3471 20.2496 14.4747V13.0854C20.2496 12.6653 19.9265 12.3422 19.5065 12.3422H16.6632V11.3406C16.6632 11.0821 16.437 10.856 16.1786 10.856H13.1414C12.8829 10.856 12.6567 11.0821 12.6567 11.3406V12.3422H9.81345C9.39341 12.3422 9.07031 12.6976 9.07031 13.0854V14.4747C9.07031 15.3471 9.74883 16.0256 10.5889 16.0256ZM12.9798 11.3729C12.9798 11.276 13.0445 11.2114 13.1414 11.2114H16.1786C16.2755 11.2114 16.3401 11.276 16.3401 11.3729V12.3745H12.9798V11.3729Z"
          fill={color}
        />
        <path
          d="M18.7634 16.3488H16.0493V16.5749C16.0493 16.8334 15.8231 17.0596 15.5647 17.0596H13.8199C13.5614 17.0596 13.3353 16.8334 13.3353 16.5749V16.3488H10.5889C9.975 16.3488 9.39341 16.0257 9.07031 15.541V19.2567C9.07031 19.6767 9.39341 19.9998 9.81345 19.9998H19.5065C19.9265 19.9998 20.2496 19.6767 20.2496 19.2567V15.541C19.9588 16.0257 19.3773 16.3488 18.7634 16.3488Z"
          fill={color}
        />
        <path
          d="M13.8207 15.6382C13.7238 15.6382 13.6592 15.7028 13.6592 15.7997V16.6075C13.6592 16.7044 13.7238 16.769 13.8207 16.769H15.5655C15.6624 16.769 15.727 16.7044 15.727 16.6075V15.7997C15.727 15.7028 15.6624 15.6382 15.5655 15.6382H13.8207Z"
          fill={color}
        />
        <path
          d="M5.87197 13.9257C5.90428 13.8611 5.90428 13.7641 5.80735 13.6995C5.74273 13.6672 5.6458 13.6672 5.58118 13.7641L5.19346 14.4103C4.25646 13.8288 3.48102 13.0533 2.89943 12.1163L3.54564 11.7286C3.61026 11.6963 3.64257 11.5994 3.61026 11.5024C3.57795 11.4378 3.48102 11.4055 3.38409 11.4378L2.73788 11.8255C2.22092 10.8885 1.93013 9.82229 1.89782 8.69144H2.64095C2.73788 8.69144 2.8025 8.62682 2.8025 8.52989C2.8025 8.43296 2.73788 8.36834 2.64095 8.36834H1.93013C1.96244 7.23748 2.25323 6.17124 2.77019 5.23425L3.4164 5.62197C3.48102 5.65428 3.57795 5.65428 3.64257 5.55735C3.67488 5.49273 3.67488 5.3958 3.57795 5.33118L2.93174 4.94346C3.51333 4.00646 4.28877 3.23102 5.22577 2.64943L5.61349 3.29564C5.71042 3.4895 6.00121 3.32795 5.90428 3.13409L5.51656 2.48788C6.45355 1.97092 7.51979 1.68013 8.65065 1.64782V2.39095C8.65065 2.48788 8.71527 2.5525 8.8122 2.5525C8.90913 2.5525 8.97375 2.48788 8.97375 2.39095V1.68013C10.1046 1.71244 11.1708 2.00323 12.1078 2.52019L11.7201 3.1664C11.6878 3.23102 11.6878 3.32795 11.7847 3.39257C11.8494 3.42488 11.9463 3.42488 12.0109 3.32795L12.3986 2.68174C13.3356 3.26333 14.1111 4.03877 14.6926 4.97577L14.0464 5.36349C13.9818 5.3958 13.9495 5.49273 13.9818 5.58966C14.0141 5.65428 14.1111 5.68659 14.208 5.65428L14.8542 5.26656C15.3712 6.20355 15.662 7.26979 15.6943 8.40065H14.9511C14.8542 8.40065 14.7896 8.46527 14.7896 8.5622C14.7896 8.65913 14.8542 8.72375 14.9511 8.72375H15.6943C15.6943 9.36995 15.565 9.98385 15.4035 10.5654H16.2435C16.5989 10.5654 16.8897 10.7916 16.9867 11.0824C17.2452 10.2746 17.3744 9.40226 17.3744 8.5622C17.3421 3.84491 13.4972 0 8.77989 0C4.0626 0 0.25 3.84491 0.25 8.52989C0.25 13.2149 4.0626 17.0275 8.74758 17.0598V15.3796C7.58441 15.3796 6.45355 15.0565 5.51656 14.5396L5.87197 13.9257Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

Watch.defaultProps = {
  color: '#585858',
};
