import React from 'react';
import clsx from 'clsx';

import { DeleteIcon, Overlay } from '../../atoms';
import { Dropzone } from '../../molecules';
import { LoadingOverlay } from './loading-overlay';

const DropzoneImage = ({
  image,
  onFileSelect,
  onFileRemove,
  widthClassName = '',
  heightClassName = '',
  readOnly,
}) => {
  return !image ? (
    <div className="flex">
      <div className={clsx('flex-shrink-0', widthClassName, heightClassName)}>
        <Dropzone onSelect={onFileSelect} readOnly={readOnly} />
      </div>
    </div>
  ) : (
    <div
      className={clsx(
        'flex relative flex-shrink-0',
        widthClassName,
        heightClassName
      )}
    >
      {readOnly && <Overlay />}
      <div className="absolute top-1 right-1">
        <button
          className="flex justify-center items-center  hover:text-red-main w-6 h-6 bg-primary rounded-50 text-white-main"
          type="button"
          onClick={onFileRemove}
          disabled={readOnly}
        >
          <DeleteIcon />
        </button>
      </div>
      <div className="drop-zone max-h-240 md:max-w-404 w-full  flex items-center justify-center">
        <img src={image} className=" max-w-full max-h-full" alt="logo" />
      </div>
    </div>
  );
};

export const EditableImageTemplate = ({
  loading,
  onFileSelect,
  onFileRemove,
  image,
  children,
  ...rest
}) => {
  return (
    <LoadingOverlay loading={loading}>
      <DropzoneImage
        {...rest}
        image={image}
        onFileSelect={onFileSelect}
        onFileRemove={onFileRemove}
      />
      {children}
    </LoadingOverlay>
  );
};
