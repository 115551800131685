import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { SchedulerLinkContent } from '../navigate-scheduler-content';

const getClassname = ({ isActive, icon, to, label, location }) => {
  const iconClass = !icon ? 'link-without-icon' : '';

  // Check specific conditions for special styling
  if (
    (label === 'Appointments' &&
      to === '/appointments' &&
      location?.pathname === '/appointment-details') ||
    (label === 'Services' &&
      to === '/service' &&
      location?.pathname === '/service-details') ||
    (label === 'Clients' &&
      to === '/clients' &&
      location?.pathname === '/client-details')
  ) {
    // Apply special styling for specific cases
    return clsx('text-primary border-primary border-l-4', iconClass);
  }

  // Exclude "My Online Store" from default active styling
  if (label === 'My Online Store') {
    return clsx('flex text-gray-dark border-l-0', iconClass);
  }

  // For other links, apply hover and active styles based on isActive
  if (!isActive) return 'hover:bg-gray-hover border-transparent';

  return clsx('text-primary border-primary border-l-4', iconClass);
};

export const NavigateSchedulerLink = ({
  to,
  label,
  icon,
  end = true,
  type,
  ...props
}) => {
  const location = useLocation();

  return (
    <NavLink
      {...props}
      to={to}
      end={end}
      className={({ isActive }) =>
        clsx(
          getClassname({ isActive, icon, to, label, location }),
          'flex text-gray-dark'
        )
      }
    >
      <SchedulerLinkContent label={label} icon={icon} type={type} />
    </NavLink>
  );
};
