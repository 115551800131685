import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client/core';
import { useBlobUpload } from '../images';
import { useRemoveAttachment } from './use-remove-attachment';

const UPDATE_CATEGORY = gql`
  mutation UpdateCategory($categoryUid: String!, $input: CategoryInput!) {
    updateCategory(categoryUid: $categoryUid, input: $input) {
      uid
    }
  }
`;

export const useUpdateCatgeory = () => {
  const [updateCatgeoryMutation] = useMutation(UPDATE_CATEGORY); // Destructure error from useMutation
  const { handleImageUpload } = useBlobUpload({});
  const { removeAttachment } = useRemoveAttachment();

  const handleUpdateCategory = async ({ categoryUid, input }) => {
    const hasImageUrl = input?.logo?.includes('http');
    try {
      const blobId =
        !hasImageUrl && input?.logo
          ? await handleImageUpload(input?.cropperBlob)
          : null;

      if (categoryUid && !input?.logo && input?.signedBlobId) {
        await removeAttachment(input?.signedBlobId);
      }

      const response = await updateCatgeoryMutation({
        variables: {
          categoryUid,
          input: {
            name: input?.name,
            coverImageSignedBlobId: blobId,
          },
        },
      });

      return response;
    } catch (err) {
      console.error('Error creating holiday:', err);
      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        // Handle GraphQL errors
        return err.graphQLErrors[0]?.message;
      }
      // Handle the error appropriately (e.g., display a user-friendly message)
      return null; // Or throw a custom error for further handling
    }
  };

  return handleUpdateCategory;
};
