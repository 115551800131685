import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import cardIcon from '../../assets/images/sub-img.svg';

const PaymentSuccessfull = () => {
  useEffect(() => {
    localStorage.setItem('isSubscribed', true);
  }, []);

  return (
    <div className="grid h-screen content-center justify-center bg-gray-verylight p-4">
      <div className=" relative bg-white-main rounded-xl  max-w-xl max-h-80 md:max-h-fit overflow-auto md:min-w-144 min-w-320 wrp-box md:p-10 p-4">
        <div className="text-center">
          <img
            className="m-auto mb-6 md:mb-8 md:max-w-66 max-w-r12"
            src={cardIcon}
            alt="logo"
          />
          <h2 className="text-black font-semibold">
            Your Subscription has been successfully created.
          </h2>
          <h2 className="text-black font-semibold">
            You may now access the Scheduler Module.
          </h2>
          {/* <h2 className="text-black font-semibold">
            <Link
              to="/service"
              className="text-blue-paypal cursor-pointer underline"
            >
              Click here
            </Link>{' '}
            to begin.
          </h2> */}
          <button className=" py-2 px-5 rounded bg-primary text-white-main hover:bg-blue-hover text-base font-medium border-primary border mt-6 md:mt-7">
            <Link to="/service">Click here to begin</Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessfull;
