import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';

const GET_WEEKLY_SCHEDULE = gql`
  query SellerScheduleStatuses($dateRange: DateRangeInput!) {
    sellerScheduleStatuses(dateRange: $dateRange) {
      shiftDayName
      shiftDate
      enabled
    }
  }
`;

export const useGetWeeklySchedule = (input) => {
  const { loading, error, data, refetch } = useQuery(GET_WEEKLY_SCHEDULE, {
    variables: {
      dateRange: input?.dateRange,
    },
  });

  return { loading, error, data, refetch };
};
