import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import close from '../../assets/images/close-crl.svg';
import reschedule from '../../assets/images/reschedule.svg';
import Modal from 'react-modal';
import { useGetAppointments, useRejectAppointment } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import DateIcon from '../../assets/images/date.png';
import TimeIcon from '../../assets/images/time.png';
import ClockIcon from '../../assets/images/clock.png';
import { yupResolver } from '@hookform/resolvers/yup';
import { appointmentRejectionSchema } from '../../constants';
import { showError, showSuccess } from '../../utils';
import { RescheduleAppointmentForm } from '../../components/templates';
import { Spinner } from '../../components/atoms';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import InfiniteScroll from 'react-infinite-scroll-component';
import { DateFilterForm } from './date-filter-form';
import moment from 'moment';

const compareValues = (a, b, order) => {
  if (order === 'asc') {
    return a > b ? 1 : a < b ? -1 : 0;
  } else {
    return a < b ? 1 : a > b ? -1 : 0;
  }
};

const formatToHoursMin = (value) => {
  const minutes = value % 60;
  const hours = Math.floor(value / 60);
  if (hours === 0) return `${minutes} min`;
  if (minutes === 0) return `${hours}h`;
  return `${hours}h ${minutes} min`;
};

const formatDate = (dateString) => {
  return moment(dateString).format('MMMM D, YYYY');
};

const isoConverter = (dateString) => {
  var date = new Date(dateString);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  var isoString = date.toISOString();

  return isoString;
};

const isoEndTimeConverter = (dateString) => {
  var date = new Date(dateString);
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  date.setMilliseconds(999);

  var isoString = date.toISOString();

  return isoString;
};

const formatTime = (dateString) => {
  return moment(dateString).format('h:mm A');
};

const Listview = ({
  data,
  refetch,
  loading,
  setFilter,
  appointmentData,
  setAppointmentData,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [fetchMoreAllowed, setFetchMoreAllowed] = useState(true);
  const [appointmentName, setappointmentName] = useState(null);

  const [RescheduleModal, setRescheduleModal] = useState(false);
  const [selectedAppointment, setselectedAppointment] = useState(null);
  const [sortedData, setSortedData] = useState([]);
  const handleRejectAppointment = useRejectAppointment();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openCalModal = () => setIsModalOpen(true);
  const closeCalModal = () => setIsModalOpen(false);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(appointmentRejectionSchema),
  });

  const [isOpen, setIsOpen] = useState(false);
  const openModal = (value) => {
    reset();
    setselectedAppointment(value);
    setIsOpen(true);
  };
  const closeModal = () => {
    reset();
    setselectedAppointment(null);
    setIsOpen(false);
  };

  const openRescheduleModal = (value) => {
    setselectedAppointment(value);
    setRescheduleModal(true);
  };
  const closeRescheduleModal = () => {
    setselectedAppointment(null);
    setRescheduleModal(false);
  };

  const goToAppointmentDetails = (data) => {
    navigate('/appointment-details', { state: data });
  };

  useEffect(() => {
    if (page === 1) {
      setAppointmentData([]);
    }
    if (data?.appointments?.nodes) {
      setFetchMoreAllowed(true);
      setAppointmentData((prevServices) => [
        ...prevServices,
        ...data?.appointments?.nodes,
      ]);
      if (data?.appointments?.pagination?.totalPages !== page) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    }
  }, [data]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    const payload = {
      appointmentUid: selectedAppointment?.uid,
      ...data,
    };
    const response = await handleRejectAppointment(payload);
    if (response?.data?.rejectAppointment) {
      setPage(1);
      setAppointmentData([]);
      showSuccess('Appointment cenceled successfully');
      closeModal();
      refetch();
      setIsLoading(false);
    } else {
      setIsLoading(false);
      showError(response);
    }
  };

  useEffect(() => {
    setFilter({
      filter: {
        startTimeGteq: startDate ? isoConverter(startDate) : null,
        startTimeLteq: endDate ? isoEndTimeConverter(endDate) : null,
        nameMatches: appointmentName || '',
      },
      pagination: {
        page: page,
        perPage: 15,
      },
    });
  }, [startDate, endDate, appointmentName, page]);

  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  useEffect(() => {
    const sortedDataValue = appointmentData.slice().sort((a, b) => {
      if (sortColumn) {
        if (
          sortColumn === 'price' ||
          sortColumn.includes('durationInMinutes')
        ) {
          const aValue =
            sortColumn === 'price'
              ? parseFloat(a.price)
              : parseFloat(a.sellerService.durationInMinutes);
          const bValue =
            sortColumn === 'price'
              ? parseFloat(b.price)
              : parseFloat(b.sellerService.durationInMinutes);

          if (!isNaN(aValue) && !isNaN(bValue)) {
            if (sortOrder === 'asc') {
              return aValue - bValue;
            } else {
              return bValue - aValue;
            }
          } else if (isNaN(aValue)) {
            return 1;
          } else if (isNaN(bValue)) {
            return -1;
          } else {
            return 0;
          }
        }
        if (sortColumn.includes('.')) {
          const data = sortColumn?.split('.');
          const aValue = a[data[0]][data[1]].charAt(0).toLowerCase();
          const bValue = b[data[0]][data[1]].charAt(0).toLowerCase();
          if (sortOrder === 'asc') {
            return aValue.localeCompare(bValue);
          } else {
            return bValue.localeCompare(aValue);
          }
        } else if (sortColumn === 'startTime') {
          const aValue = new Date(a.startTime);
          const bValue = new Date(b.startTime);
          return compareValues(aValue, bValue, sortOrder);
        }
        const aValue = a[sortColumn].charAt(0).toLowerCase();
        const bValue = b[sortColumn].charAt(0).toLowerCase();
        if (sortOrder === 'asc') {
          return aValue.localeCompare(bValue);
        } else {
          return bValue.localeCompare(aValue);
        }
      }
      return 0;
    });
    setSortedData(sortedDataValue);
  }, [appointmentData, sortColumn, sortOrder]);

  const fetchMoreData = () => {
    if (fetchMoreAllowed) {
      setPage(page + 1);
      setHasMore(false);
    }
  };

  return (
    <div>
      {(loading || isLoading) && <Spinner isBlock={false} />}
      <section className="relative flex-grow">
        <header className="my-6 flex gap-4 justify-between date-icon">
          <div className="md:flex hidden">
            <DatePicker
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
                setAppointmentData([]);
                setFetchMoreAllowed(false);
                setPage(1);
              }}
              dateFormat="MM/dd/yyyy"
              placeholderText="From"
              maxDate={endDate}
              autoComplete="off"
              onKeyDown={(e) => e.preventDefault()}
              className=" lg1:min-w-56 md:min-w-40 text-left px-3 cursor-default"
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => {
                setEndDate(date);
                setAppointmentData([]);
                setFetchMoreAllowed(false);
                setPage(1);
              }}
              dateFormat="MM/dd/yyyy"
              minDate={startDate}
              onKeyDown={(e) => e.preventDefault()}
              placeholderText="To"
              autoComplete="off"
              className=" lg1:min-w-56 md:min-w-40 text-left px-3 cursor-default ml-4"
            />
            {(startDate || endDate) && (
              <button
                className="text-sm font-semibold  focus:outline-none capitalize text-primary text-center ml-4 p-0"
                onClick={() => {
                  setStartDate(null);
                  setEndDate(null);
                  setAppointmentData([]);
                  setFetchMoreAllowed(false);
                  setPage(1);
                }}
              >
                Clear
              </button>
            )}
          </div>
          <div className="flex justify-end  flex-glow gap-2 w-full relative">
            <div className="w-full lg1:w-96 md:w-full md:max-w-md">
              <div className="relative w-full flex items-center rounded overflow-hidden border-gray-light border text-r1.125 hover:border-blue-hover hover:text-gray-middle">
                <div className="pr-2 absolute right-0">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.5">
                      <path
                        d="M12.0687 12.9807C10.5659 14.1098 8.67466 14.6424 6.78004 14.4702C4.88542 14.298 3.1299 13.4338 1.87093 12.0537C0.611964 10.6735 -0.0557828 8.8812 0.00365277 7.04162C0.0630884 5.20203 0.845238 3.45352 2.19084 2.1521C3.53645 0.850684 5.34433 0.0942198 7.24637 0.0367359C9.14842 -0.0207479 11.0016 0.625071 12.4286 1.84269C13.8556 3.06032 14.7491 4.75819 14.9272 6.59059C15.1052 8.423 14.5545 10.2522 13.3871 11.7056L17.7243 15.8847C18.0914 16.2385 18.092 16.8131 17.7255 17.1675C17.359 17.522 16.7645 17.5217 16.3984 17.1669L12.0781 12.9807H12.0687ZM7.48724 12.6913C8.22396 12.6913 8.95346 12.551 9.63409 12.2783C10.3147 12.0056 10.9332 11.606 11.4541 11.1021C11.975 10.5983 12.3883 10.0002 12.6702 9.34188C12.9521 8.6836 13.0972 7.97806 13.0972 7.26553C13.0972 6.55301 12.9521 5.84746 12.6702 5.18918C12.3883 4.5309 11.975 3.93276 11.4541 3.42893C10.9332 2.9251 10.3147 2.52545 9.63409 2.25277C8.95346 1.9801 8.22396 1.83976 7.48724 1.83976C5.99938 1.83976 4.57246 2.4114 3.52038 3.42893C2.4683 4.44646 1.87725 5.82653 1.87725 7.26553C1.87725 8.70454 2.4683 10.0846 3.52038 11.1021C4.57246 12.1197 5.99938 12.6913 7.48724 12.6913Z"
                        fill="currentColor"
                      ></path>
                    </g>
                  </svg>
                </div>
                <input
                  className="pr-6 pl-2 w-full py-1.5 text-sm placeholder-gray-dark font-normal tracking-wide outline-none border-none"
                  type="text"
                  placeholder="Search"
                  onChange={(e) => {
                    setappointmentName(e?.target?.value);
                    setAppointmentData([]);
                    setFetchMoreAllowed(false);
                    setPage(1);
                  }}
                />
              </div>
            </div>
            <button
              onClick={openCalModal}
              type="button"
              className="group relative md:hidden w-8 border border-gray-border bg-white-main text-gray-dark text-xs hover:text-gray-middle hover:border-gray-light py-1.5 text-center font-bold rounded-md flex justify-center items-center"
            >
              <svg
                className="text-primary group-hover:text-blue-hover absolute"
                width="20"
                height="11"
                viewBox="0 0 20 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.45312 9.71118H12.2031M1.57812 0.961182H19.0781H1.57812ZM4.70312 5.33618H15.9531H4.70312Z"
                  stroke="currentColor"
                ></path>
              </svg>
              <span className="hidden md:block">Filter</span>
              {startDate || endDate ? (
                <span className="red-dot block"></span>
              ) : (
                <></>
              )}
            </button>
            {isModalOpen && (
              <div className="modal-overlay">
                <div className="modal">
                  <div className="modal-content">
                    <span
                      className=" absolute right-3 top-2 font-semibold cursor-pointer"
                      onClick={closeCalModal}
                    >
                      &#x2715;
                    </span>
                    <DateFilterForm
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      startDate={startDate}
                      endDate={endDate}
                      closeCalModal={closeCalModal}
                      setPage={setPage}
                      setAppointmentData={setAppointmentData}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </header>

        <div className="border-b border-gray-light  md:border-b-2 md:border-gray-border"></div>
        <section className="relative overflow-y-auto pb-12 flex-grow ">
          <div className="infinite-scroll-component__outerdiv">
            <div className="w-full hidden md:block">
              <div
                className="infinite-scroll-component "
                id="scrollable-container"
                style={{ height: 'calc(100vh - 280px)', overflow: 'auto' }}
              >
                <InfiniteScroll
                  dataLength={sortedData?.length}
                  next={() => fetchMoreData()}
                  hasMore={hasMore}
                  scrollableTarget="scrollable-container"
                >
                  <table className="w-full main-table border-separate">
                    <thead className="bg-gray-verylight sticky top-0 z-10">
                      <tr className="border-b border-gray-highlight">
                        <th className="text-r0.625 font-semibold text-gray-middle tracking-wider truncate md:text-xs text-left max-w-1/4 pl-4">
                          ID
                        </th>
                        <th
                          className="text-r0.625 font-semibold text-gray-middle tracking-wider truncate md:text-xs text-left max-w-1/4 cursor-pointer"
                          onClick={() => handleSort('buyer.formalName')}
                        >
                          Client Name{' '}
                          {sortColumn === 'buyer.formalName' &&
                            sortOrder === 'asc' &&
                            '▲'}
                          {sortColumn === 'buyer.formalName' &&
                            sortOrder === 'desc' &&
                            '▼'}
                        </th>
                        <th
                          className="text-r0.625 font-semibold text-gray-middle tracking-wider truncate md:text-xs text-left max-w-1/4 cursor-pointer"
                          onClick={() => handleSort('sellerService.name')}
                        >
                          Service{' '}
                          {sortColumn === 'sellerService.name' &&
                            sortOrder === 'asc' &&
                            '▲'}
                          {sortColumn === 'sellerService.name' &&
                            sortOrder === 'desc' &&
                            '▼'}
                        </th>
                        <th
                          className="text-r0.625 font-semibold text-gray-middle tracking-wider truncate md:text-xs text-left max-w-1/4 cursor-pointer"
                          onClick={() => handleSort('startTime')}
                        >
                          Date{' '}
                          {sortColumn === 'startTime' &&
                            sortOrder === 'asc' &&
                            '▲'}
                          {sortColumn === 'startTime' &&
                            sortOrder === 'desc' &&
                            '▼'}
                        </th>
                        <th
                          className="text-r0.625 font-semibold text-gray-middle tracking-wider truncate md:text-xs text-left max-w-1/4 cursor-pointer"
                          onClick={() =>
                            handleSort('sellerService.durationInMinutes')
                          }
                        >
                          Duration{' '}
                          {sortColumn === 'sellerService.durationInMinutes' &&
                            sortOrder === 'asc' &&
                            '▲'}
                          {sortColumn === 'sellerService.durationInMinutes' &&
                            sortOrder === 'desc' &&
                            '▼'}
                        </th>
                        <th className="text-r0.625 font-semibold text-gray-middle tracking-wider truncate md:text-xs text-left max-w-1/4">
                          Start Time
                        </th>
                        <th className="text-r0.625 font-semibold text-gray-middle tracking-wider truncate md:text-xs text-left max-w-1/4">
                          Status
                        </th>
                        <th className="text-r0.625 font-semibold text-gray-middle tracking-wider truncate md:text-xs text-right pr-10">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedData?.length ? (
                        sortedData?.map((item, index) => {
                          const currentTime = new Date();
                          const startTime = new Date(item?.startTime);
                          const timeDifferenceInHours =
                            (startTime - currentTime) / (1000 * 60 * 60); // Difference in hours
                          return (
                            <tr
                              className="text-gray-dark cursor-pointer hover:bg-gray-lightHover bg-white-main shadow-items table-list-item "
                              key={index}
                            >
                              <td
                                onClick={() => goToAppointmentDetails(item)}
                                className="font-semibold text-xs text-black-secondary py-4 pl-4"
                              >
                                {index + 1}
                              </td>
                              <td
                                onClick={() => goToAppointmentDetails(item)}
                                className="font-semibold text-xs text-black-secondary py-4 capitalize"
                              >
                                {item?.buyer?.formalName}
                              </td>
                              <td
                                onClick={() => goToAppointmentDetails(item)}
                                className="font-semibold text-xs text-black-secondary py-4"
                              >
                                {item?.sellerService?.name}
                              </td>
                              <td
                                onClick={() => goToAppointmentDetails(item)}
                                className="font-semibold text-xs text-black-secondary py-4 whitespace-nowrap"
                              >
                                {formatDate(item?.startTime)}
                              </td>
                              <td
                                onClick={() => goToAppointmentDetails(item)}
                                className="font-semibold text-xs text-black-secondary py-4 whitespace-nowrap"
                              >
                                {formatToHoursMin(
                                  item?.sellerService?.durationInMinutes
                                )}
                              </td>
                              <td
                                onClick={() => goToAppointmentDetails(item)}
                                className="font-semibold text-xs text-black-secondary py-4"
                              >
                                {formatTime(item?.startTime)}
                              </td>
                              <td
                                onClick={() => goToAppointmentDetails(item)}
                                className="font-semibold text-xs text-black-secondary py-4"
                              >
                                {item?.status === 'accepted' ? (
                                  <div className="inline-flex rounded-full items-center py-0.5 whitespace-nowrap bg-green-lame px-3">
                                    <span className="text-xs capitalize text-green-darklime font-medium">
                                      {item?.status}
                                    </span>
                                  </div>
                                ) : item?.status === 'completed' ? (
                                  <div className="inline-flex rounded-full items-center py-0.5 whitespace-nowrap bg-gray-completed px-3">
                                    <span className="text-xs capitalize text-gray-middle font-medium">
                                      {item?.status}
                                    </span>
                                  </div>
                                ) : item?.status === 'rejected' ||
                                  item?.status === 'canceled' ? (
                                  <div className="inline-flex rounded-full items-center py-0.5 whitespace-nowrap bg-red-Verylight px-3">
                                    <span className="text-xs capitalize text-red-drk font-medium">
                                      canceled
                                    </span>
                                  </div>
                                ) : (
                                  <div className="inline-flex rounded-full items-center py-0.5 whitespace-nowrap bg-blue-chipBg px-3">
                                    <span className="text-xs capitalize text-primary font-medium">
                                      Rescheduled
                                    </span>
                                  </div>
                                )}
                              </td>
                              <td className="font-semibold text-xs text-black-secondary py-4 text-center">
                                {item?.status !== 'rejected' &&
                                  item?.status !== 'completed' &&
                                  item?.status !== 'canceled' && (
                                    <div className="flex gap-3 justify-end pr-10">
                                      {timeDifferenceInHours >= 0 && (
                                        <img
                                          src={reschedule}
                                          alt="logo"
                                          onClick={() =>
                                            openRescheduleModal(item)
                                          }
                                        />
                                      )}
                                      <img
                                        src={close}
                                        alt="logo"
                                        onClick={() => openModal(item)}
                                      />
                                    </div>
                                  )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="8" className=" h-50 w-full text-center">
                            <p className="font-semibold">
                              No Appointments Found
                            </p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </InfiniteScroll>
              </div>
            </div>
            <div className="md:hidden block">
              <div className="infinite-scroll-component__outerdiv">
                <div
                  className="infinite-scroll-component "
                  id="scrollable-container-mobile"
                  style={{ height: 'calc(100vh - 250px)', overflow: 'auto' }}
                >
                  <InfiniteScroll
                    dataLength={sortedData?.length}
                    next={() => fetchMoreData()}
                    hasMore={hasMore}
                    scrollableTarget="scrollable-container-mobile"
                  >
                    {sortedData?.length ? (
                      sortedData?.map((item, index) => {
                        const currentTime = new Date();
                        const startTime = new Date(item?.startTime);
                        const timeDifferenceInHours =
                          (startTime - currentTime) / (1000 * 60 * 60);
                        return (
                          <section
                            key={index}
                            className="grid cursor-pointer bg-white-main shadow-mobileBox p-2 rounded-lg border border-gray-apBg mb-4"
                          >
                            <div className="flex gap-3 items-start justify-between">
                              <div className="grid flex-1">
                                <p
                                  className="text-gray-middle text-sm"
                                  onClick={() => goToAppointmentDetails(item)}
                                >
                                  Name
                                </p>
                                <p className="text-gray-dark font-semibold text-sm capitalize">
                                  {item?.buyer?.formalName}
                                </p>

                                <p className="text-gray-middle text-sm mt-2">
                                  Service
                                </p>
                                <p className="text-gray-dark font-semibold text-sm">
                                  {item?.sellerService?.name}
                                </p>
                              </div>
                              <div className="grid gap-2 text-xs">
                                <div className="flex gap-3 justify-start items-center">
                                  <img src={DateIcon} alt="icon" />
                                  <p className="text-gray-middle text-xs">
                                    {formatDate(item?.startTime)}
                                  </p>
                                </div>
                                <div className="flex gap-3 justify-start items-center">
                                  <img src={TimeIcon} alt="icon" />
                                  <p className="text-gray-middle text-xs">
                                    {formatTime(item?.startTime)}
                                  </p>
                                </div>
                                <div className="flex gap-3 justify-start items-center">
                                  <img src={ClockIcon} alt="icon" />
                                  <p className="text-gray-middle text-xs">
                                    Duration:{' '}
                                    {formatToHoursMin(
                                      item?.sellerService?.durationInMinutes
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="border-b border-gray-light  md:border-b-2 md:border-gray-border my-2"></div>
                            <div className="flex justify-between gap-4 items-center">
                              {item?.status === 'accepted' ? (
                                <div className="inline-flex rounded-full items-center py-0.5 whitespace-nowrap bg-green-lame px-3">
                                  <span className="text-xs capitalize text-green-darklime font-medium">
                                    {item?.status}
                                  </span>
                                </div>
                              ) : item?.status === 'completed' ? (
                                <div className="inline-flex rounded-full items-center py-0.5 whitespace-nowrap bg-gray-completed px-3">
                                  <span className="text-xs capitalize text-gray-middle font-medium">
                                    {item?.status}
                                  </span>
                                </div>
                              ) : item?.status === 'rejected' ||
                                item?.status === 'canceled' ? (
                                <div className="inline-flex rounded-full items-center py-0.5 whitespace-nowrap bg-red-Verylight px-3">
                                  <span className="text-xs capitalize text-red-drk font-medium">
                                    canceled
                                  </span>
                                </div>
                              ) : (
                                <div className="inline-flex rounded-full items-center py-0.5 whitespace-nowrap bg-blue-chipBg px-3">
                                  <span className="text-xs capitalize text-primary font-medium">
                                    Rescheduled
                                  </span>
                                </div>
                              )}

                              {item?.status !== 'rejected' &&
                                item?.status !== 'completed' &&
                                item?.status !== 'canceled' && (
                                  <div className="flex gap-6 justify-center items-end mr-3">
                                    {timeDifferenceInHours >= 0 && (
                                      <img
                                        src={reschedule}
                                        alt="logo"
                                        onClick={() =>
                                          openRescheduleModal(item)
                                        }
                                      />
                                    )}
                                    <img
                                      src={close}
                                      alt="logo"
                                      onClick={() => openModal(item)}
                                    />
                                  </div>
                                )}
                            </div>
                          </section>
                        );
                      })
                    ) : (
                      <section>
                        <div className=" h-80 grid place-items-center w-full text-center">
                          <span className="font-semibold">
                            No Appointments Found
                          </span>
                        </div>
                      </section>
                    )}
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className={'md:w-120 w-5/6 bg-white-main'}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 31,
            backdropFilter: 'blur(2px)',
          },
          content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            border: '1px solid #ccc',
            backgroundColor: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
          },
        }}
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className=" font-semibold md:text-2xl text-base">
            Cancel Appointment
          </h2>
          <span
            className="absolute right-4 top-2 font-semibold cursor-pointer"
            onClick={closeModal}
          >
            &#x2715;
          </span>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid w-full relative">
            <textarea
              rows="4"
              placeholder="Add reason"
              className="w-full text-xs font-medium rounded border border-purple-bdr console-input p-2"
              // name="Add reason"
              {...register('comment')}
              onInput={(event) => {
                const maxLength = 200;
                const value = event.target.value;
                if (value.length > maxLength) {
                  event.target.value = value.slice(0, maxLength);
                }
              }}
            />
            {errors && errors?.comment && (
              <p className="text-red-drk md:text-r0.6875 text-r0.625">
                {errors?.comment?.message}
              </p>
            )}
          </div>

          <div className="grid grid-cols-2  gap-2  md:gap-8 mt-6">
            <button
              onClick={closeModal}
              type="button"
              className="flex-1 md:flex-none h-10 w-full  border border-gray-dark bg-very-light text-gray-dark text-xs hover:text-gray-middle hover:border-very-dark py-2 text-center font-bold rounded-md"
            >
              Close
            </button>
            <button
              type="submit"
              className="flex-1 md:flex-none h-10 w-full border bg-red-cover text-white-main  text-xs hover:bg-red-danger-hover py-2 text-center font-bold rounded-md"
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal>
      <Modal
        isOpen={RescheduleModal}
        onRequestClose={closeRescheduleModal}
        contentLabel="Example Modal"
        className={'md:w-120 w-5/6 bg-white-main'}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 31,
            backdropFilter: 'blur(2px)',
          },
          content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            border: '1px solid #ccc',
            backgroundColor: '#fff',

            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
          },
        }}
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className=" font-semibold md:text-2xl text-base">
            Reschedule Appointment
          </h2>
          <span
            className="absolute right-4 top-2 font-semibold cursor-pointer"
            onClick={closeRescheduleModal}
          >
            &#x2715;
          </span>
        </div>
        <RescheduleAppointmentForm
          setPage={setPage}
          selectedAppointment={selectedAppointment}
          setAppointmentData={setAppointmentData}
          closeRescheduleModal={closeRescheduleModal}
          setIsLoading={setIsLoading}
          refetch={refetch}
          page={page}
          setFetchMoreAllowed={setFetchMoreAllowed}
        />
      </Modal>
    </div>
  );
};

export default Listview;
