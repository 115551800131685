import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { InputLabel } from '../../atoms';
import { EditableImage } from '..';

// usage example
// specify name of field that has image itself
// and field that contains blobId for backend
// <FormImage size="full" name="imageUrl" blobIdName="blobSignedId" />;

export const FormImage = ({
  label,
  blobIdName = 'coverImageSignedBlobId',
  name,
  children,
  required,
  callBack,
  setSignedBlobId,
  setCropperBlob,
  ...props
}) => {
  const { setValue, watch } = useFormContext();
  const value = watch?.(name);
  const handleChange = useCallback(
    (value) => {
      setValue?.(name, value);
      callBack?.();
    },
    [name]
  );

  const handleBlobChange = (blobId) => {
    setValue?.(blobIdName, blobId);
  };

  return (
    <div className="form-image-container">
      {label && (
        <div className="mb-1 text-left">
          <InputLabel htmlFor={name} label={label} required={required} />
        </div>
      )}
      <div className="form-image">
        <EditableImage
          withCropper
          {...props}
          setSignedBlobId={setSignedBlobId}
          value={value}
          onChange={handleChange}
          onBlobIdChange={handleBlobChange}
          setCropperBlob={setCropperBlob}
        />
        {children}
      </div>
    </div>
  );
};
