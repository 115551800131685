import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client/core';

const REMOVE_ATTACHMENT = gql`
  mutation RemoveAttachment($signedBlobId: String!) {
    removeAttachment(signedBlobId: $signedBlobId) {
      status
    }
  }
`;

export const useRemoveAttachment = () => {
  const [removeAttachmentMutation, { loading }] =
    useMutation(REMOVE_ATTACHMENT);

  const removeAttachment = async (signedBlobId) => {
    const response = await removeAttachmentMutation({
      variables: {
        signedBlobId: signedBlobId,
      },
    });
    return response;
  };
  return {
    isLoadingRemovingAttachment: loading,
    removeAttachment,
  };
};
