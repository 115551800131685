import { FileChecksum } from '@rails/activestorage/src/file_checksum';

const createChecksum = (file) => {
  return new Promise((resolve, reject) => {
    FileChecksum.create(file, (error, checksum) => {
      if (error) {
        reject(error);
        return;
      }

      resolve(checksum);
    });
  });
};

export const createFileMetaData = async (file) => {
  const checksum = await createChecksum(file);
  const byteSize = file.size;
  const filename = file.name;
  const contentType = file.type;

  return {
    filename,
    byteSize,
    contentType,
    checksum,
  };
};
