import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client/core';

const DELETE_HOLIDAY = gql`
  mutation RemoveHoliday($holidayUid: String!) {
    removeHoliday(holidayUid: $holidayUid) {
      status
    }
  }
`;

export const useDeleteHoliday = () => {
  const [deleteHolidayMutation] = useMutation(DELETE_HOLIDAY); // Destructure error from useMutation

  const handleDeleteHoliday = async ({
    holidayUid,
    title,
    startDate,
    endDate,
  }) => {
    try {
      const response = await deleteHolidayMutation({
        variables: {
          holidayUid,
          title,
          startDate,
          endDate,
        },
      });

      return response;
    } catch (err) {
      console.error('Error creating holiday:', err);
      // Handle the error appropriately (e.g., display a user-friendly message)
      return null; // Or throw a custom error for further handling
    }
  };

  return handleDeleteHoliday;
};
