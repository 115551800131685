import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';

const GET_BUSINESS_HOURS = gql`
  query ListBusinessHoursHistories($dateRange: DateRangeInput) {
    businessHoursHistories(dateRange: $dateRange) {
      shiftDayName
      shiftDate
      histories {
        uid
        title
        startTime
        endTime
      }
    }
  }
`;

export const useGetBusinessHours = (input) => {
  const { loading, error, data, refetch } = useQuery(GET_BUSINESS_HOURS, {
    variables: {
      dateRange: input,
    },
  });

  return { loading, error, data, refetch };
};
