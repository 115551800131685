import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client/core';

const UPDATE_CLIENT = gql`
  mutation UpdateBuyerContact(
    $buyerContactUid: String!
    $input: UpdateContactInput!
  ) {
    updateBuyerContact(buyerContactUid: $buyerContactUid, input: $input) {
      phone
      formalName
    }
  }
`;

export const useUpdateClient = () => {
  const [updateClientMutation] = useMutation(UPDATE_CLIENT); // Destructure error from useMutation

  const handleUpdateClient = async (input) => {
    try {
      const response = await updateClientMutation({
        variables: {
          buyerContactUid: input?.accountUid,
          input: input?.input,
        },
      });

      return response;
    } catch (err) {
      console.log('Error creating client:', err);
      // Handle the error appropriately (e.g., display a user-friendly message)

      // Check for GraphQL errors
      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        // Handle GraphQL errors
        return err.graphQLErrors[0]?.message;
      }

      // Check for network errors
      if (err.networkError) {
        // Handle network errors
        console.error('Network error:', err.networkError);
        // You can choose to handle network errors differently
        // For instance, you can throw a custom error or return a specific response
      }

      return null; // Or throw a custom error for further handling
    }
  };

  return handleUpdateClient;
};
