import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client/core';

const STAR_SERVICE = gql`
  mutation StarSellerService($sellerServiceUid: String!) {
    starSellerService(sellerServiceUid: $sellerServiceUid) {
      status
    }
  }
`;

export const useStarService = () => {
  const [starServiceMutation] = useMutation(STAR_SERVICE); // Destructure error from useMutation

  const handleStarService = async ({ sellerServiceUid }) => {
    try {
      const response = await starServiceMutation({
        variables: {
          sellerServiceUid,
        },
      });
      return response;
    } catch (err) {
      // Check for GraphQL errors
      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        // Handle GraphQL errors
        return err.graphQLErrors[0]?.message;
        // You can choose to handle specific error codes or messages here
        // For instance, you can throw a custom error or return a specific response
      }

      // Check for network errors
      if (err.networkError) {
        // Handle network errors
        console.error('Network error:', err.networkError);
        // You can choose to handle network errors differently
        // For instance, you can throw a custom error or return a specific response
      }

      // Handle the error appropriately (e.g., display a user-friendly message)
      return null; // Or throw a custom error for further handling
    }
  };

  return handleStarService;
};
