import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';

// Define your GraphQL query
const GET_CATEGORIES_SELLER_SERVICES = gql`
  query CategoriesSellerServices {
    categoriesSellerServices {
      name
      isDefault
      sellerServices {
        uid
        name
      }
    }
  }
`;

// Define your custom hook
export const useCategoriesSellerServices = () => {
  // Use useQuery to execute the GraphQL query
  const { loading, error, data, refetch } = useQuery(
    GET_CATEGORIES_SELLER_SERVICES
  );

  // Return the loading state, error, data, and refetch function
  return { loading, error, data, refetch };
};
