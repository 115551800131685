import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client/core';

const DELETE_CATEGORY = gql`
  mutation RemoveCategory($categoryUid: String!) {
    removeCategory(categoryUid: $categoryUid) {
      status
    }
  }
`;

export const useDeleteCategory = () => {
  const [deleteCategoryMutation] = useMutation(DELETE_CATEGORY); // Destructure error from useMutation

  const handleDeleteCategory = async ({ categoryUid }) => {
    try {
      const response = await deleteCategoryMutation({
        variables: {
          categoryUid,
        },
      });

      return response;
    } catch (err) {
      console.error('Error creating holiday:', err);
      // Handle the error appropriately (e.g., display a user-friendly message)
      return null; // Or throw a custom error for further handling
    }
  };

  return handleDeleteCategory;
};
