import { useState } from 'react';

import { readFileContentsAsync, imageInAspectAsync } from '../../utils';

export const useLoadImage = ({ onChange, onError, extendToAspect } = {}) => {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleGetImage = async (file) => {
    try {
      setLoading(true);

      if (!file.type.includes('image/'))
        throw new Error('File must be of image type');

      let imageFromDisc = await readFileContentsAsync(file);

      if (typeof extendToAspect === 'number') {
        imageFromDisc = await imageInAspectAsync(imageFromDisc, extendToAspect);
      }

      setImage(imageFromDisc);
      onChange?.(imageFromDisc);

      return imageFromDisc;
    } catch (ex) {
      onError?.(ex);
      console.error('There was a problem reading file from a disk', ex);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveImage = () => {
    setImage(null);
    onChange?.(null);
  };

  return { image, handleGetImage, handleRemoveImage, loading };
};
