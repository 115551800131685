import React, { useEffect, useState } from 'react';
import Joyride, { STATUS, Step } from 'react-joyride';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { clientConfig } from '../appolo/client-config';
import { AppRoutes } from './routes';
import { ZendeskChatbot } from './zendesk-chatbot';

import '@fontsource/montserrat';

export const App = () => {
  return (
    <ApolloProvider client={clientConfig}>
      <Router>
        <AppRoutes />
        <ZendeskChatbot />
      </Router>
    </ApolloProvider>
  );
};
