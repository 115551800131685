import React from 'react';

export const DashboardIcon = (props) => {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7">
        <path
          d="M8 10.5H2C1.72386 10.5 1.5 10.2761 1.5 10V2C1.5 1.72386 1.72386 1.5 2 1.5H8C8.27614 1.5 8.5 1.72386 8.5 2V10C8.5 10.2761 8.27614 10.5 8 10.5ZM8 18.5H2C1.72386 18.5 1.5 18.2761 1.5 18V14C1.5 13.7239 1.72386 13.5 2 13.5H8C8.27614 13.5 8.5 13.7239 8.5 14V18C8.5 18.2761 8.27614 18.5 8 18.5ZM18 18.5H12C11.7239 18.5 11.5 18.2761 11.5 18V10C11.5 9.72386 11.7239 9.5 12 9.5H18C18.2761 9.5 18.5 9.72386 18.5 10V18C18.5 18.2761 18.2761 18.5 18 18.5ZM11.5 6V2C11.5 1.72386 11.7239 1.5 12 1.5H18C18.2761 1.5 18.5 1.72386 18.5 2V6C18.5 6.27614 18.2761 6.5 18 6.5H12C11.7239 6.5 11.5 6.27614 11.5 6Z"
          stroke="currentColor"
        />
      </g>
    </svg>
  );
};
