import { v4 as uuidv4 } from 'uuid';

const IMAGE_FORMAT_TYPE = 'image/png'; // The standard MIME type for the image format

export const getCroppedImage = async (image, crop) => {
  try {
    const fileName = uuidv4();

    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // As a blob
    const blob = await new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) return reject();

          blob.name = fileName;
          resolve(blob);
        },
        IMAGE_FORMAT_TYPE,
        1
      );
    });

    const base64Image = canvas.toDataURL(IMAGE_FORMAT_TYPE);

    return {
      blob,
      base64Image,
    };
  } catch (ex) {
    console.error('There was an error cropping your image', ex);
    return null;
  }
};
