import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';

const GET_SETTING = gql`
  query BusinessHoursSettingList {
    businessHoursSettings {
      nodes {
        dayName
        startTime
        endTime
        enabled
      }
    }
  }
`;

export const useGetSettings = () => {
  const { loading, error, data, refetch } = useQuery(GET_SETTING);

  return { loading, error, data, refetch };
};
