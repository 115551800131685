import { LOCAL_STORAGE_KEYS } from '../constants';

class StorageService {
  APP_PREFIX = 'HQ_WEB_';

  get isAvailable() {
    try {
      window.localStorage.setItem('key', 'value');
      return true;
    } catch (e) {
      return false;
    }
  }

  setItem(key, value) {
    if (this.isAvailable) {
      localStorage.setItem(`${this.APP_PREFIX}${key}`, JSON.stringify(value));
    }
  }

  getItem(key) {
    if (!this.isAvailable) {
      return null;
    }
    const values = localStorage.getItem(`${this.APP_PREFIX}${key}`);
    return JSON.parse(values ? values : null);
  }

  removeItem(key) {
    if (!this.isAvailable) {
      return;
    }

    localStorage.removeItem(`${this.APP_PREFIX}${key}`);
  }

  removeAllItems() {
    Object.keys(LOCAL_STORAGE_KEYS).forEach((key) => {
      this.removeItem(LOCAL_STORAGE_KEYS[key]);
    });
  }

  clearAll() {
    localStorage.clear();
  }
}

export const storageService = new StorageService();
