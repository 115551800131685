import React from 'react';
import clsx from 'clsx';

import { generateColor } from '../../../utils';

const sizes = {
  small: 'w-10 h-10',
  medium: 'w-10 h-10',
  big: 'w-16 h-16 text-2xl',
};

const ColoredSlugTemplate = ({
  name,
  uid,
  className,
  size = 'small',
  rounded = true,
}) => {
  const firstLetter = name?.[0];
  const secondLetter = name?.includes(' ') ? name?.split(' ')[1]?.[0] : '';
  const colorGuid = String(uid ?? name);

  return (
    !!firstLetter && (
      <div
        className={clsx(
          'text-white-main inline-flex items-center justify-center capitalize',
          className,
          rounded ? 'rounded' : '',
          sizes[size] ?? size
        )}
        style={{ background: generateColor(colorGuid), opacity: '0.5' }}
      >
        {firstLetter}&nbsp;{secondLetter}
      </div>
    )
  );
};

export const ColoredSlug = React.memo(ColoredSlugTemplate);
