import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client/core';
import { useBlobUpload } from '../images';
import { dataURItoBlob } from '../../utils';

const CREATE_SERVICES = gql`
  mutation CreateSellerService($input: SellerServiceInput!) {
    createSellerService(input: $input) {
      uid
    }
  }
`;

export const useCreateServices = () => {
  const [createServicesMutation] = useMutation(CREATE_SERVICES); // Destructure error from useMutation
  const { handleImageUpload } = useBlobUpload({});

  const handleCreateServices = async ({
    name,
    categoryUid,
    description,
    price,
    durationInMinutes,
    status,
    images,
  }) => {
    try {
      const transformedImages =
        images?.length &&
        (await images.reduce(async (accPromise, image) => {
          const acc = await accPromise;
          const hasImageUrl = image?.url?.includes('http');
          if (!hasImageUrl) {
            const signedBlobId = await handleImageUpload(
              dataURItoBlob(image.url)
            );
            return [...acc, { signedBlobId }];
          } else {
            return [
              ...acc,
              {
                signedBlobId: image.attachmentUid,
                // primary: false,
              },
            ];
          }
        }, Promise.resolve([])));

      const response = await createServicesMutation({
        variables: {
          input: {
            name,
            categoryUid,
            description,
            price,
            durationInMinutes,
            status,
            imagesInput: transformedImages,
          },
        },
      });

      return response;
    } catch (err) {
      console.log('Error creating holiday:', err);
      // Handle the error appropriately (e.g., display a user-friendly message)

      // Check for GraphQL errors
      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        // Handle GraphQL errors
        return err.graphQLErrors[0]?.message;
        // You can choose to handle specific error codes or messages here
        // For instance, you can throw a custom error or return a specific response
      }

      // Check for network errors
      if (err.networkError) {
        // Handle network errors
        console.error('Network error:', err.networkError);
        // You can choose to handle network errors differently
        // For instance, you can throw a custom error or return a specific response
      }

      return null; // Or throw a custom error for further handling
    }
  };

  return handleCreateServices;
};
