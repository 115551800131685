import { useState } from 'react';
import { toast } from 'react-toastify';

import { createFileMetaData, performDirectUpload } from '../../utils';
import { useDirectUpload } from './use-direct-upload';

export const useBlobUpload = ({ onChange, onBlobIdChange }) => {
  const [loading, setLoading] = useState(false);
  const [handleCreateDirectUpload] = useDirectUpload();

  const handleImageUpload = async (file) => {
    if (!file) return;

    setLoading(true);

    try {
      const fileMeta = await createFileMetaData(file);
      const res = await handleCreateDirectUpload({ variables: fileMeta });

      const { url, headers, signedBlobId } = res?.data?.createDirectUpload;
      await performDirectUpload(url, JSON.parse(headers), file);

      onBlobIdChange?.(signedBlobId);

      return signedBlobId;
    } catch (ex) {
      onChange?.(null);
      onBlobIdChange?.(undefined);

      console.error('There was a problem loading your file', ex);
      toast.error(ex);

      return null;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    handleImageUpload,
  };
};
