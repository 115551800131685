import clsx from 'clsx';
import React from 'react';

export const InputLabel = ({
  label = '',
  htmlFor = '',
  className,
  required,
  ...props
}) => {
  return (
    <label
      htmlFor={htmlFor}
      className={clsx(
        'text-xs font-medium text-gray-dark tracking-wider',
        className
      )}
      {...props}
    >
      <span className="truncate">{label}</span>
      {!!required && <span className="text-red-moderate font-bold">*</span>}
    </label>
  );
};
