import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';

const CREATE_SUBSCRIPTION_CHECKOUT = gql`
  mutation CreateSubscriptionCheckout(
    $planPriceId: String!
    $backLinks: CheckoutBackLinksInput!
  ) {
    createSubscriptionCheckout(
      planPriceId: $planPriceId
      backLinks: $backLinks
    ) {
      remoteSession {
        id
      }
    }
  }
`;

export const useCreateSubscriptionCheckout = () => {
  const [createSubscriptionCheckout, { data, loading, error }] = useMutation(
    CREATE_SUBSCRIPTION_CHECKOUT
  );

  return {
    createSubscriptionCheckout,
    data,
    loading,
    error,
  };
};
