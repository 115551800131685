import React from 'react';
import clsx from 'clsx';

import { Spinner } from '../../atoms';

export const LoadingOverlay = ({
  loading,
  remountOnLoad = false,
  children,
}) => {
  const key = remountOnLoad ? `loading_${loading}` : 'loader';
  const handleOverlayClick = loading
    ? (e) => {
        e.stopPropagation();
        e.preventDefault();
      }
    : undefined;

  return (
    <div
      onClick={handleOverlayClick}
      className={clsx('relative group', loading ? 'pointer-events-none' : '')}
    >
      <div key={key} className="loading-overlay-children">
        {children}
      </div>
      {loading && <Spinner />}
    </div>
  );
};
