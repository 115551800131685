// import { useEffect, useMemo } from 'react';
// import { ACCOUNT_TYPES } from '../constants';
// import { ZendeskAPI } from 'react-zendesk';
// import { useUser } from '../hooks';
// import { handlingZendeskWidget } from '../utils';
// import { sessionCacheStorage } from '../services';

export const ZendeskChatbot = () => {
  // const {
  //   isInited,
  //   data: { currentMarketplace, currentAccount },
  // } = useUser();
  // const chatbotId = useMemo(
  //   () => currentMarketplace?.config?.consoleHelpCenterChatbotId,
  //   [currentMarketplace?.uid]
  // );

  // useEffect(() => {
  //   if (
  //     isInited &&
  //     currentAccount?.role !== ACCOUNT_TYPES.supervisor &&
  //     chatbotId &&
  //     sessionCacheStorage.hasToken
  //   ) {
  //     handlingZendeskWidget(chatbotId).then(() => {
  //       if (ZendeskAPI) {
  //         ZendeskAPI('webWidget', 'show');
  //       }
  //     });
  //   }
  // }, [currentAccount?.uid, chatbotId, sessionCacheStorage.hasToken]);

  return null;
};
