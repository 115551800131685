const dataTypes = {
  url: 'dataUrl',
  text: 'text',
};

export const readFileContentsAsync = (file, { type = dataTypes.url } = {}) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    type === dataTypes.url
      ? reader.readAsDataURL(file)
      : reader.readAsText(file);
  });
};
