import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client/core';

const UNARCHIVE_CATEGORY = gql`
  mutation UnarchiveCategory($categoryUid: String!) {
    unarchiveCategory(categoryUid: $categoryUid) {
      status
    }
  }
`;

export const useUnArchiveCategory = () => {
  const [unArchiveCategoryMutation] = useMutation(UNARCHIVE_CATEGORY); // Destructure error from useMutation

  const handleUnArchiveCategory = async ({ categoryUid }) => {
    try {
      const response = await unArchiveCategoryMutation({
        variables: {
          categoryUid,
        },
      });

      return response;
    } catch (err) {
      console.error('Error creating holiday:', err);
      // Handle the error appropriately (e.g., display a user-friendly message)
      return null; // Or throw a custom error for further handling
    }
  };

  return handleUnArchiveCategory;
};
