import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client/core';

const ARCHIVE_CLIENT = gql`
  mutation ArchiveBuyer($accountUid: String!) {
    archiveBuyer(accountUid: $accountUid) {
      status
    }
  }
`;

export const useArchiveClient = () => {
  const [archiveClientMutation] = useMutation(ARCHIVE_CLIENT); // Destructure error from useMutation

  const handleArchiveClient = async ({ accountUid }) => {
    try {
      const response = await archiveClientMutation({
        variables: {
          accountUid,
        },
      });

      return response;
    } catch (err) {
      console.error('Error archiving client:', err);
      // Handle the error appropriately (e.g., display a user-friendly message)
      return null; // Or throw a custom error for further handling
    }
  };

  return handleArchiveClient;
};
