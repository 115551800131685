import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import BusinessDay from './business-day';
import BusinessHours from './business-hours';
import { Watch, Business } from '../icons';

export const TabsContainer = ({
  currentTab,
  setCurrentTab,
  addHoliday,
  scheduleUpdate,
}) => {
  return (
    <div>
      <Tabs className="mt-2">
        <TabList className=" border-0 flex gap-3">
          <Tab
            className=" border-0 bg-transparent cursor-pointer focus:outline-none w-1/2 md:w-auto"
            onClick={() => setCurrentTab(1)}
          >
            <div
              className={
                currentTab === 1
                  ? 'text-sm font-bold pb-2 md:pb-3 px-1 focus:outline-none capitalize text-primary border-b-3 border-primary'
                  : 'text-sm font-bold pb-2 md:pb-3 px-1 focus:outline-none capitalize text-gray-dark hover:text-gray-middle'
              }
            >
              <span className=" hidden md:block">Business Hours</span>
              <span className=" md:hidden flex justify-center">
                <Watch color={currentTab === 1 ? '#2F80ED' : '#585858'} />
              </span>
            </div>
          </Tab>
          <Tab
            className=" border-0 bg-transparent cursor-pointer focus:outline-none w-1/2 md:w-auto"
            onClick={() => setCurrentTab(2)}
          >
            <div
              className={
                currentTab === 2
                  ? 'text-sm font-bold pb-2 md:pb-3 px-1 focus:outline-none capitalize text-primary border-b-3 border-primary'
                  : 'text-sm font-bold pb-2 md:pb-3 px-1 focus:outline-none capitalize text-gray-dark hover:text-gray-middle'
              }
            >
              <span className=" hidden md:block">Business Time Off</span>
              <span className=" md:hidden flex justify-center">
                <Business color={currentTab === 2 ? '#2F80ED' : '#585858'} />
              </span>
            </div>
          </Tab>
        </TabList>

        <TabPanel>
          <BusinessHours scheduleUpdate={scheduleUpdate} />
        </TabPanel>
        <TabPanel>
          <BusinessDay addHoliday={addHoliday} />
        </TabPanel>
      </Tabs>
    </div>
  );
};
