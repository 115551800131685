import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';
const GET_BUYER_CONTACTS = gql`
  query ListBuyerContacts($accountUid: String) {
    buyerContacts(accountUid: $accountUid) {
      nodes {
        uid
        formalName
        email
        phone
      }
    }
  }
`;

export const useGetBuyerContacts = (accountUid) => {
  const { loading, error, data, refetch } = useQuery(GET_BUYER_CONTACTS, {
    variables: { accountUid },
  });

  return { loading, error, data, refetch };
};
