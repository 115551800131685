import React from 'react';
import clsx from 'clsx';
import styles from './checkbox-styles.module.scss';

import { CheckboxCheckedIcon, CheckboxIndeterminateIcon } from '../icons';

export const Checkbox = ({
  label,
  name,
  checked,
  defaultChecked,
  register,
  onChange,
  onClick,
  className,
  style,
  indeterminate,
  ...props
}) => {
  return (
    <label
      className={clsx(
        styles.containerCheckbox,
        className,
        'min-w-8 min-h-8',
        label && 'justify-start',
        indeterminate && styles.indeterminateContainerCheckbox
      )}
      style={style}
      onClick={onClick}
    >
      <input
        {...props}
        onChange={onChange}
        {...register}
        checked={checked}
        defaultChecked={defaultChecked}
        name={name}
        type="checkbox"
      />
      <span className={styles.checkmark}>
        {indeterminate ? (
          <CheckboxIndeterminateIcon />
        ) : (
          <CheckboxCheckedIcon />
        )}
      </span>
      {label && <div className="ml-2 md:text-sm text-xs "> {label}</div>}
    </label>
  );
};
