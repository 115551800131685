export const ROUTING_CONFIG = {
  schedulerDashboard: '/scheduler-dashboard',
  dashboard: '/scheduler-dashboard',
  appointments: '/appointments',
  schedulerCategories: '/scheduler-categories',
  clients: '/clients',
  service: '/service',
  manageSchedule: '/manage-schedule',
  manageSubscription: '/manage-subscription',
  homePage: '/home-page',
  paymentSuccess: '/payment-success',
};
