import React from 'react';
import ReactModal from 'react-modal';
import clsx from 'clsx';

import { H1 } from '..';
import { CloseIcon } from '../icons';

ReactModal.setAppElement('#root');

const sizes = {
  common: 'max-w-lg w-full mx-4 md:mx-auto',
  big: 'max-w-r40 w-full mx-4 sm:mx-auto',
  wide: 'max-w-3xl w-full mx-4 md:mx-auto',
  fit: 'max-w-3xl max-h-screen w-fit-content mx-4 md:mx-auto',
  mega: 'lg:h-auto max-w-232 max-h-full mx-4 md:mx-auto h-95/100 overflow-y-auto',
};

export const Modal = ({
  isOpen,
  onClose,
  children,
  className = '',
  contentClassName,
  overlayClassName = '',
  closeIcon = true,
  closeOnEscape = true,
  title,
  titleClassName,
  titleBorder = false,
  titleBottomPadding = 'pb-4',
  titleBottomMargin = 'md:mb-4 mb-1',
  size = 'common',
  bgContent = 'bg-white-main',
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={({ type }) => {
        if (type === 'click') return onClose?.();
        if (closeOnEscape && type === 'keydown') return onClose?.();
      }}
      className={clsx(
        className,
        bgContent,
        sizes[size] ?? size,
        `relative my-6 lg:my-10 rounded-md`
      )}
      overlayClassName={clsx(
        overlayClassName,
        `fixed z-40 inset-0 bg-black-mainOpacity4 flex items-center justify-center`
      )}
    >
      {closeIcon && (
        <div
          className="absolute p-4 top-0 right-0 cursor-pointer opacity-50 hover:opacity-100 focus:opacity-100 z-10"
          onClick={() => onClose && onClose()}
        >
          <CloseIcon className="text-gray-dark" />
        </div>
      )}
      {!!title && (
        <H1
          label={title}
          className={clsx(
            titleClassName,
            titleBottomMargin,
            'pt-4 md:pl-10 pl-4 pb-0  md:pb-0 md:pr-8',
            !!titleBorder && `border-b border-gray-light ${titleBottomPadding}`
          )}
        />
      )}
      <div
        className={clsx(
          'p-4 lg:p-10',
          title ? 'lg:pt-0' : 'lg:pt-12',
          contentClassName
        )}
      >
        {children}
      </div>
    </ReactModal>
  );
};
