import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';

const GET_HOLIDAYS = gql`
  query ListHolidays($pagination: PaginationInput) {
    holidays(pagination: $pagination) {
      nodes {
        uid
        title
        startDate
        endDate
      }
      pagination {
        perPage
        lastPage
        nextPage
        totalNodes
        totalPages
        currentPage
      }
    }
  }
`;

export const useGetHoliday = () => {
  const { loading, error, data, refetch } = useQuery(GET_HOLIDAYS);

  return { loading, error, data, refetch };
};
