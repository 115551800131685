import React from 'react';

export const AppointmentsIcon = (props) => {
  return (
    <div>
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="19"
        viewBox="0 0 20 19"
        fill="none"
      >
        <path
          d="M17.7153 17.633V9.27635M2.28759 9.27635V17.633M1.00195 18.2758H19.0009M7.28708 18.2758V13.0206C7.28708 12.7649 7.38867 12.5196 7.5695 12.3388C7.75033 12.158 7.99558 12.0564 8.25131 12.0564H11.8226C12.0783 12.0564 12.3236 12.158 12.5044 12.3388C12.6852 12.5196 12.7868 12.7649 12.7868 13.0206V18.2758M15.0825 1.5625H4.92035C4.04491 1.5625 3.25424 2.04462 2.91434 2.78627L1.17471 6.58454C0.588941 7.86254 1.56161 9.3105 3.07063 9.3567H3.15098C4.41252 9.3567 5.435 8.34506 5.435 7.25829C5.435 8.34305 6.45789 9.3567 7.71943 9.3567C8.98096 9.3567 10.0014 8.41738 10.0014 7.25829C10.0014 8.34305 11.0239 9.3567 12.2855 9.3567C13.547 9.3567 14.5699 8.41738 14.5699 7.25829C14.5699 8.41738 15.5924 9.3567 16.8539 9.3567H16.9323C18.4413 9.30969 19.4139 7.86174 18.8282 6.58454L17.0885 2.78627C16.7486 2.04462 15.958 1.5625 15.0825 1.5625Z"
          stroke="currentColor"
        />
      </svg>
    </div>
  );
};
