import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client/core';
import { useBlobUpload } from '../images';
import { dataURItoBlob } from '../../utils';

const UPDATE_SERVICES = gql`
  mutation UpdateSellerService(
    $input: SellerServiceInput!
    $sellerServiceUid: String!
  ) {
    updateSellerService(sellerServiceUid: $sellerServiceUid, input: $input) {
      uid
    }
  }
`;

export const useUpdateServices = () => {
  const [updateServicesMutation] = useMutation(UPDATE_SERVICES); // Destructure error from useMutation
  const { handleImageUpload } = useBlobUpload({});

  const handleUpdateServices = async ({ sellerServiceUid, input }) => {
    try {
      const transformedImages = await input?.images.reduce(
        async (accPromise, image) => {
          const acc = await accPromise;
          const hasImageUrl = image?.url?.includes('http');
          if (!hasImageUrl) {
            const signedBlobId = await handleImageUpload(
              dataURItoBlob(image.url)
            );
            return [...acc, { signedBlobId }];
          } else {
            return [
              ...acc,
              {
                signedBlobId: image?.signedBlobId,
              },
            ];
          }
        },
        Promise.resolve([])
      );
      const response = await updateServicesMutation({
        variables: {
          sellerServiceUid,
          input: {
            name: input?.name,
            categoryUid: input?.categoryUid,
            description: input?.description,
            price: input?.price,
            durationInMinutes: input?.durationInMinutes,
            status: input?.status,
            imagesInput: transformedImages,
          },
        },
      });

      return response;
    } catch (err) {
      console.log('Error updating  service:', err);
      // Handle the error appropriately (e.g., display a user-friendly message)

      // Check for GraphQL errors
      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        // Handle GraphQL errors
        return err.graphQLErrors[0]?.message;
        // You can choose to handle specific error codes or messages here
        // For instance, you can throw a custom error or return a specific response
      }

      // Check for network errors
      if (err.networkError) {
        // Handle network errors
        console.error('Network error:', err.networkError);
        // You can choose to handle network errors differently
        // For instance, you can throw a custom error or return a specific response
      }

      return null; // Or throw a custom error for further handling
    }
  };

  return handleUpdateServices;
};
