import React from 'react';
import { SpinnerIcon } from '../icons';

export const Spinner = ({ isBlock = true, className }) => {
  const classes = isBlock
    ? 'absolute w-full h-full'
    : 'fixed w-screen h-screen';

  return (
    <div
      className={`${classes} ${className} flex items-center justify-center inset-0 z-50 bg-white-opacity6`}
    >
      <SpinnerIcon />
    </div>
  );
};
