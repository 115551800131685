import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';

const GET_DASHBOARD_STATISTICS = gql`
  query DashboardStatistics($filter: AppointmentsFilterInput) {
    dashboardStatistics(filter: $filter) {
      todaysAppointmentCount
      totalCompletedAppointmentCount
      pieChartData {
        index
        label
        value
      }
      barGraphData {
        monthly {
          month
          data {
            completed
            canceled
            rescheduled
            accepted
          }
        }
        weekly {
          week
          data {
            completed
            canceled
            rescheduled
            accepted
          }
        }
      }
    }
  }
`;
export const useGetDashboardStatistics = (filter) => {
  const { loading, error, data, refetch } = useQuery(GET_DASHBOARD_STATISTICS, {
    variables: { filter },
  });

  return { loading, error, data, refetch };
};
