import React from 'react';

export const CategoriesIcon = (props) => {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        opacity="0.7"
        cx="5.5"
        cy="5.5"
        r="2.9"
        stroke="currentColor"
        strokeWidth="1.2"
      />
      <circle
        opacity="0.7"
        cx="14.5"
        cy="5.5"
        r="2.9"
        stroke="currentColor"
        strokeWidth="1.2"
      />
      <circle
        opacity="0.7"
        cx="5.5"
        cy="14.5"
        r="2.9"
        stroke="currentColor"
        strokeWidth="1.2"
      />
      <circle
        opacity="0.7"
        cx="14.5"
        cy="14.5"
        r="2.9"
        stroke="currentColor"
        strokeWidth="1.2"
      />
    </svg>
  );
};
