import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client/core';

const CREATE_BUSINESS_HOURS = gql`
  mutation SetBusinessHoursHistory(
    $input: [BusinessHoursHistoryInput!]!
    $shiftDate: ISO8601Date!
  ) {
    setBusinessHoursHistory(input: $input, shiftDate: $shiftDate) {
      status
    }
  }
`;

export const useCreateBusinessHours = () => {
  const [createBusinessHoursMutation] = useMutation(CREATE_BUSINESS_HOURS); // Destructure error from useMutation

  const handleCreateBusinessHours = async ({ input, shiftDate }) => {
    try {
      const response = await createBusinessHoursMutation({
        variables: {
          input,
          shiftDate,
        },
      });

      return response;
    } catch (err) {
      console.error('Error creating holiday:', err);
      // Handle the error appropriately (e.g., display a user-friendly message)
      return null; // Or throw a custom error for further handling
    }
  };

  return handleCreateBusinessHours;
};
