import React from 'react';

import { EditableImageWithCropper } from './editable-image-with-cropper';
import { EditableImageWithoutCropper } from './editable-image-without-cropper';

export const EditableImage = ({
  value,
  onChange,
  onBlobIdChange,
  withCropper = true,
  withUpload = true,
  setSignedBlobId,
  setCropperBlob,
  ...rest
}) => {
  const Component = withCropper
    ? EditableImageWithCropper
    : EditableImageWithoutCropper;

  return (
    <Component
      value={value}
      onChange={onChange}
      withUpload={withUpload}
      setSignedBlobId={setSignedBlobId}
      onBlobIdChange={onBlobIdChange}
      setCropperBlob={setCropperBlob}
      {...rest}
    />
  );
};
