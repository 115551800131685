import React, { useEffect, useState } from 'react';
import edit from '../../assets/images/create-outline.svg';
import ArchivedIcon from '../../components/atoms/icons/archived.icon';
import scheduleIcon from '../../assets/images/client-schedule.svg';
import Modal from './modal';
import 'react-tabs/style/react-tabs.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useArchiveClient } from '../../hooks';
import { showSuccess } from '../../utils';
import ReactModal from 'react-modal';
import BookAppointmentForm from '../../components/templates/book-appointment-form/book-appointment-form';
import { EditClientForm } from './edit-client-form';
import { useNavigate } from 'react-router-dom';

const formatPhoneNumber = (phoneNumber) => {
  const match = phoneNumber?.match(/^\+(\d)(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
  }
  return phoneNumber;
};

const ClientList = ({
  searchedText,
  data,
  page,
  setHasMore,
  fetchMoreData,
  hasMore,
  setIsLoading,
  setPage,
  refetch,
  newClient,
  props,
}) => {
  const navigate = useNavigate();
  const [isAddNewClientModalOpen, setIsAddNewClientModalOpen] = useState(false);
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
  const [restoreClientModal, setRestoreClientModal] = useState(false);
  const [clientId, setClientId] = useState(null);
  const [clientList, setClientList] = useState([]);
  const [editableData, setEditableData] = useState(null);
  const handleArchiveClient = useArchiveClient();

  const RestoreClientCloseModal = () => {
    setRestoreClientModal(false);
  };
  const RestoreClientOpenModal = () => {
    setRestoreClientModal(true);
  };

  const openAddNewClientModal = (value) => {
    setEditableData(value);
    setIsAddNewClientModalOpen(true);
  };

  const closeAddNewClientModal = () => {
    setEditableData(null);
    setIsAddNewClientModalOpen(false);
  };

  const [isAppontmentModalOpen, setIsAppontmentModalOpen] = useState(false);
  const [isAppontmentSecondModalOpen, setIsAppontmentSecondModalOpen] =
    useState(false);
  const [selectedClient, setSelectedClient] = useState(null);

  const openAppontmentModal = (item) => () => {
    setIsAppontmentModalOpen(true);
    setIsAppontmentSecondModalOpen(false); // Ensure second modal is closed when opening the AddNewClient modal
    setSelectedClient(item);
  };

  const closeAppontmentModal = () => setIsAppontmentModalOpen(false);

  useEffect(() => {
    setClientList([]);
  }, [searchedText, newClient]);

  useEffect(() => {
    if (page === 1) {
      setClientList([]);
    }
    if (data?.buyers) {
      setClientList((prevClients) => [...prevClients, ...data?.buyers?.nodes]);
      if (data?.buyers?.pagination?.totalPages !== page) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    }
  }, [data, newClient]);

  const handleArchiveClientFunc = async () => {
    setIsLoading(true);
    const payload = { accountUid: clientId };
    const response = await handleArchiveClient(payload);
    if (response?.data?.archiveBuyer?.status === 'OK') {
      setPage(1);
      setClientList([]);
      RestoreClientCloseModal();
      setClientId(null);
      refetch();
      setIsLoading(false);
      showSuccess('Client archived successfully');
    } else {
      setIsLoading(false);
    }
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleRowClick = (clientId) => {
    navigate('/client-details', { state: clientId });
  };

  return (
    <>
      <div className="w-full overflow-y-auto mt-4">
        <div className="infinite-scroll-component__outerdiv">
          <div
            className="infinite-scroll-component"
            id="scrollable-container"
            style={{ height: 'calc(100vh - 300px)', overflow: 'auto' }}
          >
            <InfiniteScroll
              dataLength={clientList?.length}
              next={fetchMoreData}
              hasMore={hasMore}
              scrollableTarget="scrollable-container"
            >
              <table className="w-full main-table border-separate main-table">
                <thead className="bg-gray-verylight sticky top-0 z-10">
                  <tr className="border-b border-gray-highlight lg1:grid lg1:grid-cols-clientTable">
                    <th className="text-r0.625 font-semibold text-gray-middle tracking-wider md:text-xs text-left pl-4">
                      Client <span className=" md:inline hidden">Name</span>
                    </th>
                    <th className="text-r0.625 font-semibold text-gray-middle tracking-wider md:text-xs text-left md:table-cell hidden">
                      Phone Number
                    </th>
                    <th className="text-r0.625 px-4 font-semibold text-gray-middle tracking-wider truncate md:text-xs text-left md:table-cell hidden">
                      Email
                    </th>
                    <th className="text-r0.625 font-semibold text-gray-middle tracking-wider md:text-xs text-left pr-3">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {clientList?.length ? (
                    clientList?.map((item, index) => (
                      <tr
                        key={index}
                        className="text-gray-dark  hover:bg-gray-lightHover bg-white-main shadow-items table-list-item lg1:grid lg1:grid-cols-clientTable items-center lg1:mb-2"
                      >
                        <td className="font-semibold text-xs text-black-secondary py-4 pl-4">
                          <div className="inline-grid gap-3">
                            <span
                              className="capitalize  no-auto-link cursor-pointer max-w-fit-content "
                              data-detector="false"
                              onClick={() => handleRowClick(item?.uid)}
                            >
                              {item?.formalName}
                            </span>
                            <span
                              className="md:hidden table-cell truncate no-auto-link"
                              data-detector="false"
                            >
                              {item?.email}
                            </span>
                            <span
                              className="md:hidden table-cell no-auto-link"
                              data-detector="false"
                            >
                              {formatPhoneNumber(item?.phone)}
                            </span>
                          </div>
                        </td>
                        <td className="font-semibold text-xs text-black-secondary py-4 md:table-cell hidden">
                          {formatPhoneNumber(item?.phone)}
                        </td>
                        <td className="font-semibold text-xs text-black-secondary py-4 md:table-cell hidden truncate">
                          {item?.email}
                        </td>
                        <td className="font-semibold text-xs text-black-secondary py-4 pr-3">
                          <div className="flex lg1:gap-2 gap-2.5 md:justify-start justify-between items-center">
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                openAppontmentModal(item)();
                              }}
                              className="lg1:border cursor-pointer lg1:border-primary min-w-r10 border-0 rounded-10 lg1:px-4 px-0 lg1:py-1 py-0 lg1:mr-2 mr-0 flex items-center gap-1"
                            >
                              <img
                                src={scheduleIcon}
                                alt="icon"
                                className="lg1:w-auto w-4.5 mt-1 lg1:mt-0"
                              />
                              <span className="text-primary lg1:block hidden truncate">
                                Book an appointment
                              </span>
                            </div>
                            <img
                              className="cursor-pointer"
                              src={edit}
                              alt="logo"
                              onClick={(e) => {
                                e.stopPropagation();
                                openAddNewClientModal(item);
                              }}
                            />
                            <div
                              className="cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                RestoreClientOpenModal();
                                setClientId(item?.uid);
                              }}
                            >
                              <ArchivedIcon />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="h-50 w-full text-center">
                        <p className="font-semibold">No Client’s Found</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </InfiniteScroll>
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={restoreClientModal}
        onRequestClose={RestoreClientCloseModal}
        className="md:w-404 w-5/6 bg-white-main"
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 31,
            backdropFilter: 'blur(2px)',
          },
          content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            border: '1px solid #ccc',
            backgroundColor: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
          },
        }}
      >
        <div className="flex justify-between items-center mb-6">
          <span
            className="absolute right-4 top-2 font-semibold cursor-pointer"
            onClick={RestoreClientCloseModal}
          >
            &#x2715;
          </span>
        </div>
        <div className="text-center mt-3">
          <p className="font-semibold">
            Are you sure you want to move client to <br />
            archived client list?
          </p>
        </div>
        <div className="grid grid-cols-2 gap-2 md:gap-8 mt-6">
          <button
            type="button"
            onClick={RestoreClientCloseModal}
            className="flex-1 md:flex-none h-10 w-full border border-gray-dark bg-very-light text-gray-dark text-xs hover:text-gray-middle hover:border-very-dark py-2 text-center font-bold rounded-md"
          >
            No
          </button>
          <button
            type="submit"
            onClick={handleArchiveClientFunc}
            className="flex-1 md:flex-none h-10 w-full border bg-primary text-white-main text-xs hover:bg-blue-hover py-2 text-center font-bold rounded-md"
          >
            Yes
          </button>
        </div>
      </ReactModal>

      <Modal isOpen={isAppontmentModalOpen} onClose={closeAppontmentModal}>
        <h2 className="font-semibold md:text-2xl text-base text-gray-dark">
          Book an appointment
        </h2>
        <BookAppointmentForm
          setPage={setPage}
          selectedClient={selectedClient}
          setClientList={setClientList}
          closeAppontmentModal={closeAppontmentModal}
          setIsLoading={setIsLoading}
          refetch={refetch}
        />
      </Modal>
      <Modal isOpen={isAddNewClientModalOpen} onClose={closeAddNewClientModal}>
        <EditClientForm
          editableData={editableData}
          setIsLoading={setIsLoading}
          closeAddNewClientModal={closeAddNewClientModal}
          refetch={refetch}
          setPage={setPage}
        />
      </Modal>
    </>
  );
};
export default ClientList;
