import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';

const GET_CATEGORIES = gql`
  query ListCategories($pagination: PaginationInput, $archived: Boolean) {
    categories(pagination: $pagination, archived: $archived) {
      nodes {
        uid
        name
        isDefault
        sellerServicesCount
        coverImage {
          signedBlobId
          url
          thumbUrl
        }
      }
      pagination {
        perPage
        lastPage
        nextPage
        totalNodes
        totalPages
        currentPage
      }
    }
  }
`;

export const useGetCategories = (input) => {
  const { loading, error, data, refetch } = useQuery(GET_CATEGORIES, {
    variables: input,
  });

  return { loading, error, data, refetch };
};
