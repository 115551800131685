import React from 'react';

import { Cropper } from '../editable-image/cropper';
import { ActionButton, ModalButtonsContainer, Modal } from '../../atoms';

export const CropperModal = ({
  isOpen,
  image,
  title = 'Edit Image',
  handleClose,
  cropperProps = {},
  handleCroppingImageChange,
  handleCroppedImageUpload,
  disabledButton,
}) => {
  return (
    <Modal
      title={title}
      size="fit"
      closeIcon
      isOpen={isOpen}
      onClose={handleClose}
    >
      <Cropper
        imageStyle={{ maxHeight: '50vh' }}
        {...cropperProps}
        image={image}
        onImageCropped={handleCroppingImageChange}
      />
      {/* <ModalButtonsContainer> */}
      <button
        onClick={handleCroppedImageUpload}
        disabled={disabledButton}
        className="order-primary bg-primary text-white-main text-xs hover:bg-blue-hover h-10 w-full border b py-2 text-center font-bold rounded-md mt-4"
      >
        Save
      </button>
      {/* </ModalButtonsContainer> */}
    </Modal>
  );
};
