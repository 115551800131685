import * as yup from 'yup';

export const BusinessFormSchema = yup.object().shape({
  title: yup
    .string()
    .max(50, 'Please enter less than 50 letters')
    .required('Please enter title')
    .matches(
      /^[a-zA-Z0-9][a-zA-Z0-9\s]*$/,
      'Please avoid special characters & leading space'
    ),
  startDate: yup.string().required('Please select start date'),
  endDate: yup
    .string()
    .required('Please select end date')
    .when('startDate', (startDate, schema) => {
      return schema.test({
        test: (endDate) => {
          if (startDate && endDate) {
            // Check if endDate is greater than startDate
            return endDate > startDate || endDate === startDate;
          }
          return true;
        },
        message: 'End date must be later than or equal to start date',
      });
    }),
});
