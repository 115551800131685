import React, { useCallback } from 'react';
import { toast } from 'react-toastify';

import { useBlobUpload, useLoadImage } from '../../../hooks';
import { EditableImageTemplate } from './editable-image.template';

export const EditableImageWithoutCropper = ({
  value,
  onChange,
  onBlobIdChange,
  withUpload = false,
  ...rest
}) => {
  const { handleGetImage } = useLoadImage({
    onChange,
    onError: () =>
      toast.error(
        'Image format is not valid. The supported format is jpg,jpeg,png'
      ),
  });
  const handleRemoveImage = () => {
    onChange?.(null);
    onBlobIdChange?.(null);
  };
  const { loading, handleImageUpload } = useBlobUpload({
    onChange,
    onBlobIdChange,
  });

  const handleFilePickerClick = useCallback(async (file) => {
    const fileFromDisk = await handleGetImage(file);
    // we check if file is loaded by this request
    // but still send the file inself, cause handleImageUpload works with files
    if (withUpload) {
      !!fileFromDisk && handleImageUpload(file);
    } else {
      onBlobIdChange?.(null);
    }
  }, []);

  return (
    <EditableImageTemplate
      {...rest}
      image={value}
      loading={loading}
      onFileRemove={handleRemoveImage}
      onFileSelect={handleFilePickerClick}
    />
  );
};
