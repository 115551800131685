import React from 'react';

export const Archived = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          d="M12.4023 2.58794C12.4733 2.65974 12.5292 2.74072 12.5709 2.82755C12.626 2.94359 12.6569 3.07298 12.6578 3.21075V3.22661C12.6536 3.43115 12.5818 3.61898 12.4624 3.76759C12.449 3.78512 12.434 3.80182 12.419 3.81768L10.7777 5.59675C10.4437 5.95824 9.8802 5.98078 9.51871 5.64684C9.15722 5.31373 9.13468 4.75021 9.46862 4.38872L9.73327 4.10237H6.424C5.93227 4.10237 5.5332 3.70331 5.5332 3.21158C5.5332 2.71985 5.93227 2.32078 6.424 2.32078H9.61722L9.35591 2.0603C9.00861 1.71217 9.00861 1.14865 9.35591 0.800513C9.70404 0.453214 10.2676 0.453214 10.6157 0.800513L12.3956 2.58125L12.4023 2.58794Z"
          fill="#585858"
        />
        <path
          d="M2.98456 5.32292C2.38346 4.89131 1.65798 4.908 1.1053 5.22691C0.560133 5.54166 0.189453 6.14023 0.189453 6.84485V14.65C0.189453 16.1377 1.32402 17.46 2.86098 17.46H15.3287C16.8657 17.46 18.0002 16.1376 18.0002 14.65V6.84485C18.0002 6.14023 17.6295 5.54164 17.0844 5.22691C16.5317 4.90799 15.8062 4.8913 15.2051 5.32292L9.56327 9.37702C9.27524 9.58406 8.91458 9.58406 8.62657 9.37702L2.98456 5.32292ZM15.3287 15.6784C15.7512 15.6784 16.116 15.3703 16.2003 14.9412C16.2855 14.5121 16.0676 14.0797 15.6794 13.9077L10.1467 11.4499C9.47552 11.1519 8.71413 11.1519 8.04289 11.4499L2.51026 13.9077C2.12206 14.0797 1.90417 14.5121 1.98932 14.9412C2.07364 15.3703 2.43847 15.6784 2.8609 15.6784H15.3287Z"
          fill="#585858"
        />
      </svg>
    </div>
  );
};
