import { useCallback, useState } from 'react';

export const useCropper = (image) => {
  const [croppingImage, setCroppingImage] = useState(image);
  const [croppedBlob, setCroppedBlob] = useState(null);
  const [cropRect, setCropRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const handleCroppingImageChange = useCallback(
    (imageInfo) => {
      if (!imageInfo) {
        setCroppedBlob(null);
        return setCroppingImage(null);
      }

      const { blob, base64Image, x, y, width, height } = imageInfo;
      setCroppedBlob(blob);
      setCroppingImage(base64Image);
      setCropRect({ x, y, width, height });
    },
    [setCroppingImage, setCroppedBlob, setCropRect]
  );

  return { croppingImage, croppedBlob, cropRect, handleCroppingImageChange };
};
