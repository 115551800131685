import React from 'react';

export const CheckboxIndeterminateIcon = (props) => {
  return (
    <svg
      {...props}
      width="10"
      height="9"
      viewBox="0 0 10 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="3" width="9" height="2" rx="2" fill="white" />
    </svg>
  );
};
