import React from 'react';

export const DeleteIcon = (props) => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.60547 3.3457L3.3544 15.3287C3.38998 16.021 3.89364 16.527 4.5527 16.527H11.4429C12.1046 16.527 12.5989 16.021 12.6412 15.3287L13.3901 3.3457"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.41016 3.3457H14.5914"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M10.697 5.7424L10.3974 14.1305M5.60426 3.34581V1.84794C5.60392 1.72982 5.62693 1.6128 5.67197 1.50361C5.71701 1.39441 5.7832 1.2952 5.86672 1.21168C5.95024 1.12816 6.04945 1.06197 6.15865 1.01693C6.26784 0.971887 6.38486 0.948877 6.50298 0.949223H9.49872C9.61684 0.948877 9.73386 0.971887 9.84306 1.01693C9.95225 1.06197 10.0515 1.12816 10.135 1.21168C10.2185 1.2952 10.2847 1.39441 10.3297 1.50361C10.3748 1.6128 10.3978 1.72982 10.3974 1.84794V3.34581H5.60426ZM8.00085 5.7424V14.1305V5.7424ZM5.30469 5.7424L5.60426 14.1305L5.30469 5.7424Z"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
