import React from 'react';

import { TrashBlackIcon } from '../../atoms';
import clsx from 'clsx';

export const ItemImage = ({
  value,
  onFileRemove,
  disabledDelete,
  isPrimaryImage = false,
  setAsPrimaryImageHandler,
  index,
}) => {
  return (
    <div
      className={clsx(
        isPrimaryImage
          ? 'flex relative flex-shrink-0 w-7 h-28 md:w-34 md:h-34 mb-2 md:mb4 rounded-md border-primary border-2'
          : 'flex relative flex-shrink-0 w-7 h-28 md:w-34 md:h-34 mb-2 md:mb4 rounded-md bg-gray-light border border-gray-middle'
      )}
    >
      {!disabledDelete && (
        <div className="absolute top-1 right-1">
          <button
            className="flex justify-center items-center hover:text-red-main w-6 h-6 rounded-50 text-white-main"
            type="button"
            onClick={onFileRemove}
            disabled={disabledDelete}
          >
            <TrashBlackIcon className="w-6 h-6" />
          </button>
        </div>
      )}
      <img src={value?.url} alt="image" className="rounded-md w-full h-full" />
    </div>
  );
};
