import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';

const GET_AVAILABLE_APPOINTMENT_SLOTS = gql`
  query availableAppointmentSlots(
    $sellerServiceUid: String!
    $shiftDate: ISO8601Date!
    $appointmentUid: String!
  ) {
    availableAppointmentSlots(
      sellerServiceUid: $sellerServiceUid
      shiftDate: $shiftDate
      appointmentUid: $appointmentUid
    ) {
      avaliableTimeSlots
    }
  }
`;

export const useGetAvailableAppointmentSlots = (input) => {
  const { loading, error, data, refetch } = useQuery(
    GET_AVAILABLE_APPOINTMENT_SLOTS,
    {
      variables: {
        sellerServiceUid: input?.sellerServiceUid,
        shiftDate: input?.shiftDate,
        appointmentUid: input?.appointmentUid,
      },
    }
  );
  return { loading, error, data, refetch };
};
