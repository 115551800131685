import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';

const CURRENT_STOREFRONT_QUERY = gql`
  query CurrentStorefront {
    currentStorefront {
      name
      slug
      primaryColor
      secondaryColor
      logoUrl
      longLogoUrl
      shopUrl
      seller {
        formalName
        email
        phone
      }
      subscriptionExpiryDate
      futureBookingsEnabled
    }
  }
`;

export const useGetCurrentStorefrontDetails = () => {
  const { loading, error, data, refetch } = useQuery(CURRENT_STOREFRONT_QUERY);

  return { loading, error, data, refetch };
};
