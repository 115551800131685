import React from 'react';

const EditIcon = ({ width = 18, height = 18 }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        // width="18"
        // height="18"
        width={width}
        height={height}
        viewBox="0 0 18 18"
        fill="none"
      >
        <g opacity="0.5">
          <path
            d="M13.5 7.875V14.3438C13.5 14.5284 13.4636 14.7113 13.393 14.8819C13.3223 15.0525 13.2187 15.2075 13.0881 15.3381C12.9575 15.4687 12.8025 15.5723 12.6319 15.643C12.4613 15.7136 12.2784 15.75 12.0938 15.75H3.65625C3.28329 15.75 2.9256 15.6018 2.66188 15.3381C2.39816 15.0744 2.25 14.7167 2.25 14.3438V5.90625C2.25 5.53329 2.39816 5.1756 2.66188 4.91188C2.9256 4.64816 3.28329 4.5 3.65625 4.5H9.54422"
            stroke="#585858"
          />
          <path
            d="M14.8478 2.21447L14.848 2.21427L15.2828 1.78159L15.2829 1.7815L15.3534 1.85238C15.4075 1.79848 15.4719 1.75609 15.5428 1.72777C15.6136 1.69946 15.6895 1.68581 15.7658 1.68765C15.8421 1.68949 15.9173 1.70678 15.9867 1.73848C16.0561 1.77018 16.1184 1.81562 16.1698 1.87207L14.8478 2.21447ZM14.8478 2.21447C14.7764 2.28596 14.7363 2.38289 14.7363 2.48396C14.7363 2.58503 14.7764 2.68197 14.8478 2.75346L14.8479 2.75355L15.2464 3.15129C15.2464 3.15133 15.2464 3.15137 15.2465 3.15141L14.8478 2.21447ZM13.9687 3.09329L13.9687 3.09325C14.0468 3.01541 14.1525 2.9717 14.2627 2.9717C14.373 2.9717 14.4787 3.01541 14.5568 3.09325L14.557 3.09353L14.9068 3.44509L14.907 3.44529C14.9842 3.52325 15.0274 3.62849 15.0274 3.73816C15.0274 3.84783 14.9842 3.95308 14.907 4.03104L14.9067 4.03135L8.57165 10.3784L13.9687 3.09329ZM13.9687 3.09329L7.62234 9.42836L13.9687 3.09329ZM7.3915 10.7809C7.43294 10.7915 7.47644 10.7906 7.51742 10.7785L7.51744 10.7785L7.52085 10.7774L8.39231 10.4845M7.3915 10.7809L8.57157 10.3785C8.52176 10.4284 8.46007 10.4649 8.39231 10.4845M7.3915 10.7809C7.35007 10.7704 7.31224 10.7489 7.28202 10.7187M7.3915 10.7809L7.28202 10.7187M8.39231 10.4845C8.39148 10.4848 8.39065 10.485 8.38982 10.4853L8.36263 10.389L8.39448 10.4838L8.39231 10.4845ZM7.22328 10.4799L7.22334 10.4799L7.51617 9.6077C7.53577 9.53994 7.57227 9.47825 7.62226 9.42844L7.22328 10.4799ZM7.22328 10.4799L7.22226 10.4833M7.22328 10.4799L7.22226 10.4833M7.22226 10.4833C7.21011 10.5243 7.20925 10.5678 7.21977 10.6092M7.22226 10.4833L7.21977 10.6092M7.21977 10.6092C7.23029 10.6506 7.2518 10.6885 7.28202 10.7187M7.21977 10.6092L7.28202 10.7187"
            fill="#585858"
            stroke="#585858"
          />
        </g>
      </svg>
    </div>
  );
};

export default EditIcon;
