import React from 'react';

export const CheckboxCheckedIcon = (props) => {
  return (
    <svg
      {...props}
      width="10"
      height="9"
      viewBox="0 0 10 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.01172 4.20788L4.2936 7.39846L8.99951 1"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
