import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client';

const CREATE_DIRECT_UPLOAD = gql`
  mutation CreateDirectUpload(
    $filename: String!
    $byteSize: Int!
    $checksum: String!
    $contentType: String!
  ) {
    createDirectUpload(
      input: {
        filename: $filename
        byteSize: $byteSize
        checksum: $checksum
        contentType: $contentType
      }
    ) {
      url
      signedBlobId
      headers
    }
  }
`;

export const useDirectUpload = () => {
  return useMutation(CREATE_DIRECT_UPLOAD);
};
