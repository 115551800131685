import * as yup from 'yup';

export const manageSettingsSchema = yup.object().shape({
  days: yup.array().of(
    yup.object().shape({
      enabled: yup.boolean().required(),
      time: yup.array().when('enabled', {
        is: true,
        then: yup.array().of(
          yup.object().shape({
            startTime: yup.string().required('Start time is required'),
            endTime: yup.string().required('End time is required'),
          })
        ),
        otherwise: yup.array().of(
          yup.object().shape({
            startTime: yup.string(),
            endTime: yup.string(),
          })
        ),
      }),
    })
  ),
});
// Function to convert time to minutes
const convertToMinutes = (time) => {
  const [hour, minute, period] = time.split(/[:\s]/);
  let hourValue = parseInt(hour);
  if (period === 'PM' && hour !== '12') {
    hourValue += 12;
  } else if (period === 'AM' && hour === '12') {
    hourValue = 0;
  }
  return hourValue * 60 + parseInt(minute);
};
