import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCreateClient } from '../../hooks';
import { addCLientFormSchema } from '../../constants';
import { showError, showSuccess } from '../../utils';
import InputMask from 'react-input-mask';

export const AddClientForm = ({
  setNewClient,
  setIsLoading,
  closeAddNewClientModal,
  refetch,
  setPage,
  newClient,
  pageView,
  openFirstModal,
  setNewClientData,
}) => {
  const handleCreateClient = useCreateClient();
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: yupResolver(addCLientFormSchema),
  });
  const [error, setError] = useState('');

  function convertToInternationalFormat(phoneNumber) {
    // Remove all non-numeric characters
    var cleanedNumber = phoneNumber.replace(/\D/g, '');

    // If the number starts with '1', remove it
    if (cleanedNumber.charAt(0) === '1') {
      cleanedNumber = cleanedNumber.substr(1);
    }

    // Add the country code
    var internationalNumber = '+1' + cleanedNumber;

    return internationalNumber;
  }

  const onSubmit = async (data) => {
    setIsLoading(true);

    const payload = {
      email: data?.email,
      formalName: data?.name,
      phone: convertToInternationalFormat(data?.mobile),
    };
    const response = await handleCreateClient(payload);
    if (response?.data?.createBuyerContact) {
      if (pageView === 'client-list') {
        setPage(1);
        refetch();
        setNewClient(!newClient);
      }
      showSuccess('Client added successfully');
      closeAddNewClientModal();
      reset();
      setIsLoading(false);
      if (pageView === 'calendar-view') {
        setNewClientData(response?.data?.createBuyerContact);
        openFirstModal();
      }
    } else {
      // Displaying the error message
      setIsLoading(false);
      showError(response);
    }
  };

  return (
    <>
      <h2 className="font-semibold md:text-2xl text-base text-gray-dark">
        Add New Client
      </h2>
      <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="my-3">
          <label className="text-sm  text-gray-dark">
            Email <span className="text-red-drk">*</span>
          </label>
          <Controller
            name="email"
            control={control}
            defaultValue="" // provide a default value if necessary
            render={({ field }) => (
              <input
                {...field}
                type="text"
                className="w-full h-10 text-xs font-medium rounded-lg border border-gray-light console-input p-2"
                placeholder="Email"
              />
            )}
          />
          {errors && errors?.email && (
            <p className="text-red-drk md:text-r0.6875 text-r0.625 ">
              {errors?.email?.message}
            </p>
          )}
        </div>
        <div className="my-3">
          <label className="text-sm  text-gray-dark">
            Client Name <span className="text-red-drk">*</span>
          </label>
          <Controller
            name="name"
            control={control}
            defaultValue="" // provide a default value if necessary
            render={({ field }) => (
              <input
                {...field}
                type="text"
                className="w-full h-10 text-xs font-medium rounded-lg border border-gray-light console-input p-2"
                placeholder="Name"
              />
            )}
          />
          {errors && errors?.name && (
            <p className="text-red-drk md:text-r0.6875 text-r0.625 ">
              {errors?.name?.message}
            </p>
          )}
        </div>

        <div className="my-3">
          <label className="text-sm  text-gray-dark">
            Mobile Number <span className="text-red-drk">*</span>
          </label>
          <Controller
            name="mobile"
            control={control}
            defaultValue="" // provide a default value if necessary
            render={({ field }) => (
              <InputMask
                {...field}
                placeholder="Enter your phone number"
                mask="+1 (999) 999 9999"
                alwaysShowMask
                maskChar="_"
                inputMode="numeric"
                className={`w-full text-xs font-medium rounded border border-gray-mercury console-input p-2 my-2 ${
                  errors.mobileNumber ? 'border-red-500' : ''
                }`}
                onChange={(e) => {
                  field.onChange(e);
                }}
              />
            )}
          />
          {errors && errors?.mobile && (
            <p className="text-red-drk md:text-r0.6875 text-r0.625 ">
              {errors?.mobile?.message}
            </p>
          )}
        </div>
        <button
          className={
            !isValid
              ? 'text-gray-dark w-full p-2 mt-1 rounded-lg bg-gray-mercury'
              : ' w-full p-2 mt-1 rounded-lg bg-primary text-white-main hover:bg-blue-hover'
          }
          disabled={!isValid}
        >
          Add Client
        </button>
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </form>
    </>
  );
};
