import * as yup from 'yup';

export const appointmentRejectionSchema = yup.object().shape({
  comment: yup
    .string()
    .required('Please enter reason of rejection')
    .matches(
      /^[a-zA-Z0-9][a-zA-Z0-9\s]*$/,
      'Please avoid special characters & leading space'
    ),
});
