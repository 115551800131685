import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { ROUTING_CONFIG } from '../../../constants';

import { SideBarTemplate } from '../side-bar';
import { CompanyLogo, MenuIcon } from '../../atoms';

import 'react-toastify/dist/ReactToastify.css';

export const MainTemplate = ({ children }) => {
  const navigate = useNavigate();

  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const { pathname } = useLocation();

  const openSideBar = () => {
    setIsSideBarOpen(true);
  };

  const closeSideBar = () => {
    setIsSideBarOpen(false);
  };

  // close opened sidebar on location pathname change (navigation)
  useEffect(() => {
    if (!isSideBarOpen) {
      return;
    }

    closeSideBar();
  }, [pathname]);

  const [isFlutterInAppWebViewReady, setIsFlutterInAppWebViewReady] =
    useState(false);
  const [receivedData, setReceivedData] = useState('');

  console.log('receivedData === ', receivedData);
  useEffect(() => {
    const handleFlutterReady = () => {
      setIsFlutterInAppWebViewReady(true);
      const args = [1, true, ['bar', 5], { foo: 'baz' }];
      if (
        window.flutter_inappwebview &&
        window.flutter_inappwebview.callHandler
      ) {
        window.flutter_inappwebview.callHandler('testHandler', ...args);
      }
    };

    const handleMessageFromFlutter = (event) => {
      console.log('event.origin === ', JSON.stringify(event?.data));
      // if (event.origin === 'https://scheduler.dev.salonhq.co') {
      // Replace with your ReactJS app URL
      console.log('Received message from Flutter:', event.data?.data);
      setReceivedData(event.data?.data);
      // }
    };

    window.addEventListener(
      'flutterInAppWebViewPlatformReady',
      handleFlutterReady
    );
    window.addEventListener('message', handleMessageFromFlutter);

    // Simulate the event for testing purposes
    setTimeout(() => {
      window.dispatchEvent(new Event('flutterInAppWebViewPlatformReady'));
    }, 1000); // Adjust the delay as needed

    return () => {
      window.removeEventListener(
        'flutterInAppWebViewPlatformReady',
        handleFlutterReady
      );
      window.removeEventListener('message', handleMessageFromFlutter);
    };
  }, []);

  const sendMessageToFlutter = () => {
    if (isFlutterInAppWebViewReady) {
      const args = [1, true, ['bar', 5], { foo: 'baz' }];
      if (
        window.flutter_inappwebview &&
        window.flutter_inappwebview.callHandler
      ) {
        window.flutter_inappwebview.callHandler('testHandler', ...args);
      } else {
        console.log('flutter_inappwebview.callHandler is not defined.');
      }
    } else {
      console.log('Flutter InAppWebView is not ready yet.');
    }
  };

  return (
    <div className="flex min-h-screen box-border guide-home flex-col">
      <div className="top-0 right-0 left-0 bg-white-main h-12 shadow-block z-10 flex items-center justify-center md:hidden min-w-65">
        <MenuIcon
          className="absolute w-12 h-12 text-primary p-4 left-0 top-0 cursor-pointer"
          onClick={openSideBar}
        />
        <CompanyLogo clickHandler={sendMessageToFlutter} />
        <div
          className="absolute w-12 h-12 text-primary p-4 pt-3 right-0 top-0 cursor-pointer"
          id="howToIcon"
        />
      </div>
      <nav
        className={clsx(
          'fixed flex h-full w-60 px-6 overflow-y-auto shadow-sideBarShadow z-30 bg-white-main transition-all md:left-0 md:transform-none',
          isSideBarOpen ? 'left-0' : '-left-60'
        )}
      >
        <SideBarTemplate
          onSelectMarketplace={() => navigate(ROUTING_CONFIG.selectMarketplace)}
          onCloseSideBar={closeSideBar}
        />
      </nav>
      <div
        className={clsx(
          'z-20 bg-black-mainOpacity4 top-0 right-0 bottom-0 left-0 fixed transition-opacity md:hidden',
          isSideBarOpen
            ? 'opacity-1 pointer-events-auto'
            : 'opacity-0 pointer-events-none'
        )}
        onClick={closeSideBar}
      />

      <main className="flex flex-col h-full md:min-h-screen flex-grow overflow-y-hidden md:ml-60">
        <React.Fragment>{children}</React.Fragment>
      </main>
    </div>
  );
};
