import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';

const GET_SUBSCRIPTION_PLAN = gql`
  query SubscriptionPlan {
    subscriptionPlan {
      uid
      name
      description
      validForDays
      price
      planPriceId
      activeSubscription {
        uid
        currentPeriodEndedAt
        active
        createdAt
        canceledAt
        subscriptionPlanPrice {
          validForDays
          price
          isDefault
        }
      }
    }
  }
`;

export const useGetSubscriptionPlan = () => {
  const { loading, error, data, refetch } = useQuery(GET_SUBSCRIPTION_PLAN);

  return { loading, error, data, refetch };
};
