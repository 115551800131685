import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';

const SELLER_CONSOLE_LINK_QUERY = gql`
  query SellerConsoleLink {
    sellerConsoleLink {
      url
    }
  }
`;

export const useGetSellerConsoleLink = () => {
  const { loading, error, data, refetch } = useQuery(SELLER_CONSOLE_LINK_QUERY);

  return { loading, error, data, refetch };
};
