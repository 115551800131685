import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client/core';

const CREATE_SESSION = gql`
  mutation CreateSessionWithToken($token: String!) {
    createSessionWithToken(token: $token) {
      token
      marketplaceUid
      isSubscribed
    }
  }
`;

export const useCreateSession = () => {
  const [createSessionMutation] = useMutation(CREATE_SESSION); // Destructure error from useMutation

  const handleCreateSession = async ({ token }) => {
    try {
      const response = await createSessionMutation({
        variables: {
          token,
        },
      });

      return response;
    } catch (err) {
      console.log('Error creating Session:', err);
      // Handle the error appropriately (e.g., display a user-friendly message)

      // Check for GraphQL errors
      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        // Handle GraphQL errors
        return err.graphQLErrors[0]?.extensions?.details?.base[0];
        // You can choose to handle specific error codes or messages here
        // For instance, you can throw a custom error or return a specific response
      }

      // Check for network errors
      if (err.networkError) {
        // Handle network errors
        console.error('Network error:', err.networkError);
        // You can choose to handle network errors differently
        // For instance, you can throw a custom error or return a specific response
      }

      return null; // Or throw a custom error for further handling
    }
  };

  return handleCreateSession;
};
