import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import { CloudUploadIcon } from '../../atoms';

const baseStyle = {
  flex: 1,
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  // height: '100%',
  // padding: '25px 24px',
  // borderWidth: 2,
  // borderRadius: 4,
  // borderColor: 'var(--color-gray-middle)',
  // borderStyle: 'dashed',
  // backgroundColor: 'var(--color-white-main)',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  boxSizing: 'border-box',
};

const acceptStyle = {
  borderColor: 'var(--color-green-light)',
};

const rejectStyle = {
  borderColor: 'var(--color-red)',
};

const disabledStyle = {
  opacity: '0.5',
};

export const Dropzone = ({ onSelect, readOnly = false, className }) => {
  const onDrop = useCallback((files) => {
    if (files?.length) {
      onSelect?.(files[0]);
    }
  }, []);

  const onDropRejected = useCallback((files) => {
    if (files?.length) {
      files[0].errors.forEach(({ code }) => {
        if (code === 'file-invalid-type') {
          toast.error('File type must be jpeg or png');
        }

        if (code === 'file-too-large') {
          toast.error('File is larger than 5 Mb');
        }
      });
    }
  }, []);

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      accept: 'image/jpeg, image/png, image/jpg',
      maxSize: '5242880', // 5mb
      multiple: false,
      onDrop,
      onDropRejected,
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(readOnly ? disabledStyle : {}),
    }),
    [isDragAccept, isDragReject, readOnly]
  );

  return (
    <div
      {...getRootProps({ style })}
      className={
        className
          ? `${className} w-full p-2 h-28 md:w-34 md:h-34 mb-2 md:mb4  border border-dashed border-gray-middle rounded bg-gray-mercury`
          : 'drop-zone h-240 md:w-404 w-full border border-dashed border-gray-middle flex items-center justify-center rounded bg-gray-mercury p-1'
      }
    >
      <CloudUploadIcon />
      <input {...getInputProps()} disabled={readOnly} />
      <p className="mt-4 text-r0.625 font-bold tracking-wider text-center">
        {'Drag and drop image here or '}
        <span className="text-primary underline">choose a file</span>
      </p>
    </div>
  );
};
