export const LOCAL_STORAGE_KEYS = {
  token: 'TOKEN',
  email: 'EMAIL',
  marketplace: 'MARKETPLACE',
  account: 'ACCOUNT',
  tenant: 'TENANT',
  cart: 'CART_',
  storeSlug: 'STORE_SLUG',
  isError: 'IS_ERROR',
  onboardingSteps: 'STEPS',
  loginVia: 'LOGIN_VIA',
  hint: 'HINT',
};
