import React from 'react';

export const TrashBlackIcon = (props) => {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4359_48971)">
        <rect width="16" height="16" rx="8" fill="#F6F6F6" />
        <path
          d="M5.24219 5.24023L5.62546 11.3726C5.64367 11.7269 5.90142 11.9858 6.2387 11.9858H9.7648C10.1034 11.9858 10.3564 11.7269 10.378 11.3726L10.7613 5.24023"
          stroke="#8F8E8E"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.625 5.24023H11.3706"
          stroke="#8F8E8E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M9.37675 6.46661L9.22344 10.7593M6.7705 5.24014V4.4736C6.77032 4.41315 6.78209 4.35327 6.80515 4.29738C6.8282 4.2415 6.86207 4.19073 6.90481 4.14799C6.94755 4.10524 6.99833 4.07137 7.05421 4.04832C7.11009 4.02527 7.16997 4.0135 7.23042 4.01367H8.76351C8.82396 4.0135 8.88385 4.02527 8.93973 4.04832C8.99561 4.07137 9.04638 4.10524 9.08912 4.14799C9.13187 4.19073 9.16574 4.2415 9.18879 4.29738C9.21184 4.35327 9.22362 4.41315 9.22344 4.4736V5.24014H6.7705ZM7.99697 6.46661V10.7593V6.46661ZM6.61719 6.46661L6.7705 10.7593L6.61719 6.46661Z"
          stroke="#8F8E8E"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4359_48971">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
