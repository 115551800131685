import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client/core';

const PUBLISH_SERVICES = gql`
  mutation PublishSellerServices {
    publishSellerServices {
      status
    }
  }
`;

export const usePublishServices = () => {
  const [publishServicesMutation] = useMutation(PUBLISH_SERVICES); // Destructure error from useMutation

  const handlePublishServices = async () => {
    try {
      const response = await publishServicesMutation({
        variables: {},
      });

      return response;
    } catch (err) {
      console.log('Error creating holiday:', err);
      // Handle the error appropriately (e.g., display a user-friendly message)

      // Check for GraphQL errors
      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        // Handle GraphQL errors
        return err.graphQLErrors?.[0]?.message;
        // You can choose to handle specific error codes or messages here
        // For instance, you can throw a custom error or return a specific response
      }

      // Check for network errors
      if (err.networkError) {
        // Handle network errors
        console.error('Network error:', err.networkError);
        // You can choose to handle network errors differently
        // For instance, you can throw a custom error or return a specific response
      }

      return null; // Or throw a custom error for further handling
    }
  };

  return handlePublishServices;
};
