import React, { useCallback, useEffect, useState } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useCreateBusinessHours } from '../../../hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { showError, showSuccess } from '../../../utils';
import { DeleteIcon } from '../icons/delete.icon';
import { editFormSchema } from '../../../constants';
import dayjs from 'dayjs'; // Import dayjs
import Select from 'react-select';
import { Spinner } from '../spinner';
import ResetIcon from '../icons/clear-reset.icon';
import { ScheduleConfirmationModal } from '../schedule-confirmation-modal/confirmation-modal';
import moment from 'moment';

const convertTo24HourFormat = (time12h) => {
  const [time, modifier] = time12h.split(' ');

  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
};

const convertTimeToISO8601DateTime = (time) => {
  if (!time) return '';

  // Get today's date
  const today = moment().format('YYYY-MM-DD');

  // Convert the time to 24-hour format
  const time24 = convertTo24HourFormat(time);

  // Combine date and time in local time zone and convert to ISO 8601 format
  const iso8601DateTime = moment(
    `${today} ${time24}`,
    'YYYY-MM-DD HH:mm'
  ).toISOString(true);

  return iso8601DateTime;
};

export const EditForm = ({ editableData, closeModal, refetch }) => {
  const handleCreateBusinessHours = useCreateBusinessHours();
  const [timeOptions, setTimeOptions] = useState([]);
  const [timeUpdateCount, setTimeUpdateCount] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(editFormSchema),
    defaultValues: {
      shifts: [
        {
          startTime: '',
          endTime: '',
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'shifts', // Name for the field array
  });

  const convertData = (data) => {
    let convertedData = [];

    data?.forEach((item) => {
      // Flatten the nested time array
      // Create a new object with the desired format
      const newItem = {
        endTime: item.endTime ? convertTimeToISO8601DateTime(item.endTime) : '',
        startTime: item.startTime
          ? convertTimeToISO8601DateTime(item.startTime)
          : '',
      };

      // Add the new object to the converted data array
      convertedData.push(newItem);
    });

    return convertedData;
  };
  const [scheduleConfirmationModal, setScheduleConfirmationModal] =
    useState(false);
  const [scheduleConfirmationModalData, setScheduleConfirmationModalData] =
    useState(false);

  // Define confirmation callback function
  const confirmationCallback = async (confirmed, formData) => {
    if (confirmed) {
      setIsLoading(true);
      // Proceed with form submission
      const convertedData = convertData(formData?.shifts);
      const payload = {
        input: convertedData,
        shiftDate: editableData?.shiftDate,
      };

      const response = await handleCreateBusinessHours(payload);
      if (response?.data?.setBusinessHoursHistory) {
        showSuccess('Business hours updated successfully');
        refetch();
        closeModal();
        setIsLoading(false);
      } else {
        setIsLoading(false);
        showError(response);
      }
    } else {
      // Handle cancellation
      setIsLoading(false);
      closeScheduleConfirmationModal();
      closeModal();
    }
  };

  const onSubmit = async (data) => {
    openScheduleConfirmationModal(data); // Pass the form data to the confirmation modal
  };

  // Open confirmation modal function
  const openScheduleConfirmationModal = (formData) => {
    setScheduleConfirmationModalData(formData); // Store the form data in state
    setScheduleConfirmationModal(true);
  };

  // Close confirmation modal function
  const closeScheduleConfirmationModal = () => {
    setScheduleConfirmationModal(false);
  };

  useEffect(() => {
    if (editableData?.shiftDate) {
      const updatedHistories = editableData.histories.map((history) => ({
        ...history,
        startTime: dayjs(history.startTime).format('hh:mm A'),
        endTime: dayjs(history.endTime).format('hh:mm A'),
      }));
      setValue('shifts', updatedHistories);
    }
  }, [editableData]);

  useEffect(() => {
    const newTimeOptions = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 5) {
        const hour12 = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
        const period = hour < 12 ? 'AM' : 'PM';
        const hourStr = hour12.toString().padStart(2, '0');
        const minuteStr = minute.toString().padStart(2, '0');
        const label = `${hourStr}:${minuteStr} ${period}`;
        const time = `${hourStr}:${minuteStr} ${period}`;
        const indexId = hour + 1;
        newTimeOptions.push({ value: time, label, indexId });
      }
    }
    setTimeOptions(newTimeOptions);
  }, []);

  const getSlotTimeOption = useCallback(
    (type, data, timeItem, timeIndex) => {
      let newTimeOptiosn = JSON.parse(JSON.stringify(timeOptions));

      // Remove the options from Dropdown which are already selected.
      data?.forEach((existingTimeSlot, index) => {
        if (index === timeIndex) return; // If same time slot range, Don't limit/filter the range.

        const startTime = Date.parse(
          `01/01/2000 ${existingTimeSlot.startTime}`
        );
        const endTime = Date.parse(`01/01/2000 ${existingTimeSlot.endTime}`);

        newTimeOptiosn = newTimeOptiosn.filter((timeList) => {
          const dropdownTimeOption = Date.parse(`01/01/2000 ${timeList.value}`);
          return dropdownTimeOption < startTime || dropdownTimeOption > endTime;
        });
      });

      // Validate start time.
      if (type === 'startTime' && timeItem.endTime) {
        const currentEndTime = Date.parse(`01/01/2000 ${timeItem.endTime}`);
        // Start time Always must be less than end time if selected.
        newTimeOptiosn = newTimeOptiosn.filter((timeList) => {
          const dropdownTimeOption = Date.parse(`01/01/2000 ${timeList.value}`);
          return dropdownTimeOption < currentEndTime;
        });

        // and should be greater that nearest privious end time
        let timelist = JSON.parse(JSON.stringify(data));
        // remove the current index
        timelist.splice(timeIndex, 1);
        timelist = timelist.filter((item) => {
          return Date.parse(`01/01/2000 ${item.endTime}`) < currentEndTime;
        });
        let nearestEndTime = Date.parse(`01/01/2000 12:00 AM`);
        timelist.forEach((item) => {
          const currentEndTime = Date.parse(`01/01/2000 ${item.endTime}`);
          if (currentEndTime > nearestEndTime) {
            nearestEndTime = currentEndTime;
          }
        });

        newTimeOptiosn = newTimeOptiosn.filter((timeList) => {
          const dropdownTimeOption = Date.parse(`01/01/2000 ${timeList.value}`);
          return dropdownTimeOption >= nearestEndTime;
        });
      }

      // Validate end time
      if (type === 'endTime' && timeItem.startTime) {
        const currentStartTIme = Date.parse(`01/01/2000 ${timeItem.startTime}`);
        // end time Always must be greather start time and less that nearest next start time.
        newTimeOptiosn = newTimeOptiosn.filter((timeList) => {
          const dropdownTimeOption = Date.parse(`01/01/2000 ${timeList.value}`);
          return dropdownTimeOption > currentStartTIme;
        });

        // finding const nearestNextStartTime = '';
        let timelist = JSON.parse(JSON.stringify(data));
        // remove the current index
        timelist.splice(timeIndex, 1);
        // Time list which are greater than current start time
        timelist = timelist.filter((item) => {
          return Date.parse(`01/01/2000 ${item.endTime}`) > currentStartTIme;
        });

        let nearestNextStartTime = Date.parse(`01/01/2000 11:59 PM`);
        timelist.forEach((item) => {
          const currentStartTime = Date.parse(`01/01/2000 ${item.startTime}`);
          if (currentStartTime < nearestNextStartTime) {
            nearestNextStartTime = currentStartTime;
          }
        });

        newTimeOptiosn = newTimeOptiosn.filter((timeList) => {
          const dropdownTimeOption = Date.parse(`01/01/2000 ${timeList.value}`);
          return dropdownTimeOption < nearestNextStartTime;
        });
      }

      return newTimeOptiosn;
    },
    [timeOptions, timeUpdateCount]
  );

  const customSelectSearchInput = ({ ...props }) => {
    return (
      <input
        {...props}
        maxLength={10}
        style={{ width: 'auto', maxWidth: '85px', padding: '2px 5px' }}
      /> // Set the input style
    );
  };

  const handleClearField = (index) => {
    const updatedFields = [...fields];
    if (index === 0 && updatedFields.length > 0) {
      updatedFields[0] = {
        endTime: '',
        startTime: '',
      };
      setValue('shifts', updatedFields);
    }
  };

  return (
    <>
      {isLoading && <Spinner />}
      <form className="mt-4  pr-5" onSubmit={handleSubmit(onSubmit)}>
        {fields?.length ? (
          fields.map((item, index) => (
            <div
              key={item.id}
              className="grid grid-cols-gAuto md:gap-4 gap-1 mt-4"
            >
              <div>
                <label className="md:text-base text-xs"> Start Time </label>
                <Controller
                  key={`startTime-${item.id}`}
                  name={`shifts.[${index}].startTime`}
                  control={control}
                  defaultValue={item?.startTime}
                  render={({ field }) => (
                    <Select
                      className="react-select-container text-sm"
                      classNamePrefix="react-select"
                      options={getSlotTimeOption(
                        'startTime',
                        fields,
                        item,
                        index
                      )}
                      value={timeOptions.find(
                        (option) => option.value === field.value
                      )}
                      onChange={(selectedOption) => {
                        setTimeUpdateCount((c) => c + 1);
                        const updatedFields = [...fields];
                        updatedFields[index].startTime = selectedOption.value;
                        setValue('shifts', updatedFields);
                      }}
                      placeholder="Start Time"
                      styles={{
                        control: (base) => ({
                          ...base,
                          fontSize: '12px',
                          margin: '0px',
                        }),
                      }} // Adjust the font size as needed
                      components={{ Input: customSelectSearchInput }} // Custom input component
                    />
                  )}
                />
                {errors?.shifts?.[index]?.startTime && (
                  <p className="text-red-drk text-r0.6875">
                    {errors.shifts[index].startTime.message}
                  </p>
                )}
              </div>
              <div>
                <label className="md:text-base text-xs">End Time</label>
                <Controller
                  key={`endTime-${item.id}`}
                  name={`shifts[${index}].endTime`}
                  control={control}
                  defaultValue={item.endTime}
                  render={({ field }) => (
                    <Select
                      className="react-select-container text-sm"
                      classNamePrefix="react-select"
                      options={getSlotTimeOption(
                        'endTime',
                        fields,
                        item,
                        index
                      )}
                      value={timeOptions.find(
                        (option) => option.value === field.value
                      )}
                      onChange={(selectedOption) => {
                        setTimeUpdateCount((c) => c + 1);
                        const updatedFields = [...fields];
                        updatedFields[index].endTime = selectedOption.value;
                        setValue('shifts', updatedFields);
                      }}
                      placeholder="End Time"
                      styles={{
                        control: (base) => ({
                          ...base,
                          fontSize: '12px',
                          margin: '0px',
                        }),
                      }} // Adjust the font size as needed
                      components={{ Input: customSelectSearchInput }} // Custom input component
                    />
                  )}
                />
                {errors?.shifts?.[index]?.endTime && (
                  <p className="text-red-drk text-r0.6875">
                    {errors.shifts[index].endTime.message}
                  </p>
                )}
              </div>
              <div className="flex items-center">
                {index > 0 && (
                  <button
                    type="button"
                    onClick={() => remove(index)}
                    className="text-gray-middle text-xs mt-7 cursor-pointer"
                  >
                    <DeleteIcon />
                  </button>
                )}
                {index === 0 && (
                  <button
                    type="button"
                    onClick={() => {
                      handleClearField(0);
                    }}
                    className="text-gray-middle text-xs mt-7 cursor-pointer"
                  >
                    {/* Clear */}
                    <ResetIcon />
                  </button>
                )}
              </div>
            </div>
          ))
        ) : (
          <></>
        )}
        {fields?.length <= 4 ? (
          <button
            type="button"
            onClick={() => append({ startTime: '', endTime: '' })}
            className="py-2 px-4 mt-4 rounded border border-primary text-primary hover:bg-gray-hover font-semibold"
          >
            + Add a Shift
          </button>
        ) : (
          <></>
        )}
        <button
          type="submit"
          className="w-full p-2 mt-4 rounded bg-primary text-white-main hover:bg-blue-hover"
        >
          Save
        </button>
      </form>
      <ScheduleConfirmationModal
        apptCount=""
        scheduleConfirmationModal={scheduleConfirmationModal}
        closeScheduleConfirmationModal={closeScheduleConfirmationModal}
        confirmationCallback={
          (confirmed) =>
            confirmationCallback(confirmed, scheduleConfirmationModalData) // Pass the stored form data to the confirmation callback
        }
        actionType=""
      />
    </>
  );
};
