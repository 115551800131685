import React, { useState } from 'react';
import { CropperModal, Dropzone } from '../../molecules';
import { useCropper, useLoadImage } from '../../../hooks';
import { toast } from 'react-toastify';
import { CROPPER_PROPS } from '../../../constants';

export const MultiImagesDropzone = ({ className, handleAddingImage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);

  const { image, handleGetImage } = useLoadImage({
    onError: () =>
      toast.error(
        'Image format is not valid. The supported format is jpg,jpeg,png'
      ),
    extendToAspect: CROPPER_PROPS.aspect,
  });

  const { croppingImage, croppedBlob, handleCroppingImageChange } =
    useCropper(image);

  const handleFilePickerClick = async (file) => {
    if (file.type.includes('gif') || file.type.includes('svg')) {
      return toast.error(
        'Image format is not valid. The supported format is jpg,jpeg,png'
      );
    }
    const imageFromDisc = await handleGetImage(file);
    !!imageFromDisc && setIsOpen(true);
  };

  const handleCroppedImageUpload = () => {
    handleAddingImage(croppingImage);
    setIsOpen(false);
  };

  return (
    <div className="w-full md:w-auto">
      <div className="w-auto h-28 md:w-34 md:h-34 mb-2 md:mb4">
        <Dropzone onSelect={handleFilePickerClick} className={className} />
      </div>
      <CropperModal
        isOpen={isOpen}
        image={image}
        handleCroppingImageChange={handleCroppingImageChange}
        handleCroppedImageUpload={handleCroppedImageUpload}
        disabled={!croppedBlob}
        cropperProps={CROPPER_PROPS}
        handleClose={handleClose}
      />
    </div>
  );
};
