const accountForAspect = ({ aspect, img }) => {
  const { width = 0, height = 0 } = img ?? {};
  const imageAspect = width / height;

  if (imageAspect === aspect) return { width, height };

  return imageAspect > aspect
    ? { width: height * aspect, height: height }
    : { width: width, height: width / aspect };
};

const accountForScale = (
  { width, height, maxHeightImage, maxWidthImage } = {},
  scale = 1.0
) => {
  const scaleWidth =
    width > maxWidthImage ? maxWidthImage * scale : width * scale;
  const scaleHeight =
    height > maxHeightImage ? maxHeightImage * scale : height * scale;

  return {
    width: scaleWidth,
    height: scaleHeight,
    maxHeightImage: scaleHeight / scale,
    maxWidthImage: scaleWidth / scale,
  };
};

export const calcOptimalCrop = ({ aspect, img, scale = 0.8 } = {}) => {
  const sizes = accountForAspect({ aspect, img });
  const { width, height, maxHeightImage, maxWidthImage } = accountForScale(
    sizes,
    scale
  );

  const y = (img.height - height) / 2;
  const x = (img.width - width) / 2;

  return {
    unit: 'px',
    width,
    height,
    x,
    y,
    aspect,
    maxWidth: maxWidthImage,
    maxHeight: maxHeightImage,
  };
};
