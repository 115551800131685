import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client/core';
import { useBlobUpload } from '../images';

const CREATE_CATEGORY = gql`
  mutation CreateCategory($input: CategoryInput!) {
    createCategory(input: $input) {
      uid
    }
  }
`;

export const useCreateCategory = () => {
  const [createCategoryMutation] = useMutation(CREATE_CATEGORY); // Destructure error from useMutation
  const { handleImageUpload } = useBlobUpload({});

  const handleCreateCategory = async (input) => {
    try {
      const blobId = await handleImageUpload(input?.cropperBlob);

      const response = await createCategoryMutation({
        variables: {
          input: {
            name: input?.name,
            coverImageSignedBlobId: blobId,
          },
        },
      });
      return response;
    } catch (err) {
      console.error('Error creating holiday:', err);

      // Check for GraphQL errors
      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        // Handle GraphQL errors
        return err.graphQLErrors[0]?.message;
        // You can choose to handle specific error codes or messages here
        // For instance, you can throw a custom error or return a specific response
      }

      // Check for network errors
      if (err.networkError) {
        // Handle network errors
        console.error('Network error:', err.networkError);
        // You can choose to handle network errors differently
        // For instance, you can throw a custom error or return a specific response
      }

      // Handle the error appropriately (e.g., display a user-friendly message)
      return null; // Or throw a custom error for further handling
    }
  };

  return handleCreateCategory;
};
