import React from 'react';
import clsx from 'clsx';

export const H1 = ({ label, className, title }) => {
  return (
    <h1
      title={title}
      className={clsx(
        'font-bold text-lg text-gray-dark text-left md:text-xl lg:text-2xl truncate',
        className
      )}
    >
      {label}
    </h1>
  );
};

export const H2 = ({ label, className }) => {
  return (
    <h2
      className={clsx('font-bold text-xl text-gray-dark text-left', className)}
    >
      {label}
    </h2>
  );
};

export const PageTitle = ({
  label,
  className = 'mb-5',
  labelClassName,
  children,
}) => {
  const content = children ? (
    children
  ) : (
    <H1 className={labelClassName} label={label} title={label} />
  );
  return <div className={clsx(className)}>{content}</div>;
};
