import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client/core';

const CREATE_SETTINGS = gql`
  mutation SetBusinessHoursSetting($input: [BusinessHoursSettingInput!]!) {
    setBusinessHoursSetting(input: $input) {
      status
    }
  }
`;

export const useCreateSettings = () => {
  const [createSettingsMutation] = useMutation(CREATE_SETTINGS); // Destructure error from useMutation

  const handleCreateSettings = async ({ input }) => {
    try {
      const response = await createSettingsMutation({
        variables: {
          input,
        },
      });

      return response;
    } catch (err) {
      console.error('Error creating holiday:', err.graphQLErrors);

      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        // Handle GraphQL errors
        return err.graphQLErrors[0]?.message;
        // You can choose to handle specific error codes or messages here
        // For instance, you can throw a custom error or return a specific response
      }

      // Check for network errors
      if (err.networkError) {
        // Handle network errors
        console.error('Network error:', err.networkError);
        // You can choose to handle network errors differently
        // For instance, you can throw a custom error or return a specific response
      }
      // Handle the error appropriately (e.g., display a user-friendly message)
      return null; // Or throw a custom error for further handling
    }
  };

  return handleCreateSettings;
};
