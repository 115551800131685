import React, { useEffect, useState } from 'react';
import category from '../../assets/images/dummyImage.png';
import { useLocation, useNavigate } from 'react-router';
const formatToHoursMin = (value) => {
  const minutes = value % 60;
  const hours = Math.floor(value / 60);
  if (hours === 0) return `${minutes} min`;
  if (minutes === 0) return `${hours}h`;
  return `${hours}h ${minutes} min`;
};

const ServiceDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [serviceDetails, setServiceDetails] = useState(null);

  const handleClick = () => navigate(-1);

  useEffect(() => {
    if (location?.state) {
      setServiceDetails(location?.state);
    }
  }, [location]);
  return (
    <div>
      <section className="flex flex-col relative h-screen bg-gray-verylight pt-12 md:pt-0 md:bg-gray-main px-3 md:px-0">
        <div className="pl-4 pr-4 bg-gray-verylight md:bg-gray-main md:pl-8 md:pr-8">
          <div className="pb-3 pt-3  w-full md:pb-5 md:pt-5 lg:pb-7 lg:pt-8 relative gap-x-2">
            <div className="flex justify-between items-center">
              <div>
                <div className="hidden md:block">
                  <span
                    className="text-xs text-gray-middle cursor-pointer"
                    onClick={handleClick}
                  >
                    Services
                  </span>
                  <span className="text-xs text-gray-middle mx-1"> &gt;</span>
                  <span className="text-xs text-gray-dark">
                    Service Details
                  </span>
                </div>
                <h1 className="font-bold text-lg text-black text-left md:text-xl lg:text-2xl truncate">
                  Service Details
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="border-b border-gray-light  md:border-b-2 md:border-gray-border"></div>
        <section className="relative overflow-y-auto pt-5 pb-22 flex-grow md:pl-8 md:pr-8 pl-2 pr-2">
          <div className="text-gray-dark mb-4 cursor-pointer  hover:bg-gray-lightHover bg-white-main shadow-items grid  gap-4 p-4 rounded bottom-1 border-gray-light w-full lg:max-w-555">
            <div className="flex items-center justify-start gap-x-2 md:gap-x-3">
              <div className="h-12 w-12 p-1 rounded block">
                <div className="aspect-w-1 aspect-h-1">
                  <img
                    className="rounded"
                    src={serviceDetails?.images[0]?.url || category}
                    alt="icon"
                  />
                </div>
              </div>
              <div className="grid py-2 px-1 md:py-0 md:px-0">
                <span className="font-semibold text-xs text-black-secondary">
                  {serviceDetails?.name}
                </span>
                <span className="font-semibold text-xs text-black-secondary">
                  {formatToHoursMin(serviceDetails?.durationInMinutes)}
                </span>
              </div>
            </div>
            <div className="flex justify-between gap-4">
              <div className="font-semibold text-xs text-black-secondary">
                <p>Category</p>
                <p> {serviceDetails?.category?.name}</p>
              </div>
              <div className="font-semibold text-xs text-black-secondary">
                <p>Price</p>
                <p>$ {serviceDetails?.price}</p>
              </div>
              <div>
                <div
                  className={
                    serviceDetails?.status === 'published'
                      ? 'inline-flex rounded-full items-center py-0.5 whitespace-nowrap bg-green-lame px-3'
                      : serviceDetails?.status === 'draft'
                      ? 'inline-flex rounded-full items-center py-0.5 whitespace-nowrap bg-blue-glow px-3'
                      : 'inline-flex rounded-full items-center py-0.5 whitespace-nowrap bg-red-Verylight px-3'
                  }
                >
                  <span
                    className={
                      serviceDetails?.status === 'published'
                        ? 'text-xs capitalize text-green-darklime font-medium'
                        : serviceDetails?.status === 'draft'
                        ? 'text-xs capitalize text-gray-middle font-medium'
                        : 'text-xs capitalize text-red-drk font-medium'
                    }
                  >
                    {serviceDetails?.status === 'deleted'
                      ? 'Archived'
                      : serviceDetails?.status}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
};

export default ServiceDetails;
