import * as yup from 'yup';

// headers must === fields
export const validateHeaders = (fields = [], headers = []) => {
  if (!fields || !headers) return false;
  if (fields.length !== headers.length) return false;

  return fields.some((f) => !headers.includes(f));
};

export const validateData = async (data, schema) => {
  const dataSchema = yup.array(schema);

  return await dataSchema.isValid(data);
};
