import React from 'react';

export const MenuIcon = (props) => {
  return (
    <svg
      {...props}
      width="19"
      height="14"
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 1H14.1667H1.5M17.5 13H14.1667H1.5M17.5 7H7.5H1.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
