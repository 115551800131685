export const PER_PAGE = 50;

export const SECONDARY_IMAGES_PER_PRODUCT = 8;
export const DEFAULT_FORM_MODE = 'onChange';
export const MAX_QTY = 999;
export const CROPPER_PROPS = {
  aspect: 1,
};
export const DEFAULT_COUNTRY_CODE = 'US';
export const DEFAULT_ALLOWED_LANGUAGE = 'EN';
export const GUIDE_MORE_INFO_URL = 'https://vimeo.com/834104538';
export const GUIDE_URL_FOR_CATALOG = 'https://vimeo.com/822735363';
export const TRACKING_NUMBER_URL = 'https://www.google.com.ua/search?q=';
