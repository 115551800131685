import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { getCroppedImage, calcOptimalCrop } from '../../../utils';

export const Cropper = ({
  image,
  onImageCropped,
  aspect = 1 / 1,
  maxWidth = 2000,
  maxHeight = 2000,
  allowSaveOnInitialCrop = true,
  imageStyle = {},
}) => {
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({
    aspect: aspect,
  });
  const [completedCrop, setCompletedCrop] = useState(null);

  const onLoad = useCallback(
    (event) => {
      const img = event.target;
      imgRef.current = img;
      const optimalCrop = calcOptimalCrop({ aspect, img, maxWidth, maxHeight });
      setCrop(optimalCrop);
      allowSaveOnInitialCrop && setCompletedCrop(optimalCrop);

      return false; // Return false if you set crop state in here.
    },
    [aspect]
  );

  // const onLoad = useCallback(
  //   (img) => {
  //     imgRef.current = img;
  //     console.log('img === ', img);
  //     const optimalCrop = calcOptimalCrop({ aspect, img, maxWidth, maxHeight });
  //     setCrop(optimalCrop);
  //     allowSaveOnInitialCrop && setCompletedCrop(optimalCrop);

  //     return false; // Return false if you set crop state in here.
  //   },
  //   [aspect]
  // );

  useEffect(() => {
    const performImageCrop = async () => {
      if (!completedCrop || !imgRef.current) {
        return;
      }

      const image = imgRef.current;
      const croppedImages = await getCroppedImage(image, completedCrop);
      if (!croppedImages) return onImageCropped?.(null);

      const { blob, base64Image } = croppedImages;

      onImageCropped?.({ blob, base64Image });
    };

    performImageCrop();
  }, [completedCrop]);

  useEffect(() => {
    imgRef.current = imgRef.current || document.createElement('img');
    imgRef.current.src = image;
    imgRef.current.onload = onLoad;
  }, [image, onLoad]);

  return (
    <>
      <ReactCrop
        crop={crop}
        maxWidth={crop.maxWidth}
        maxHeight={crop.maxHeight}
        onImageLoaded={onLoad}
        onChange={(c) => setCrop(c)}
        onComplete={(c) => setCompletedCrop(c)}
        className="w-full flex justify-center"
        aspect={aspect}
      >
        <img
          src={image}
          onLoad={onLoad}
          ref={imgRef}
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            ...imageStyle,
          }}
        />
      </ReactCrop>
      {/* <ReactCrop
        src={image}
        crop={crop}
        maxWidth={crop.maxWidth}
        maxHeight={crop.maxHeight}
        onImageLoaded={onLoad}
        onChange={(c) => setCrop(c)}
        onComplete={(c) => setCompletedCrop(c)}
        imageStyle={imageStyle}
      /> */}
    </>
  );
};
