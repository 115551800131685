import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import trash from '../../../assets/images/trash_icn.svg';
import EditIcon from '../icons/editIcon';
import { holidayFormSchema } from '../../../constants';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import {
  useGetHoliday,
  useUpdateHoliday,
  useDeleteHoliday,
  useAppointmentsCountForHoliday,
} from '../../../hooks';
import { Controller, useForm } from 'react-hook-form';
import { showError, showSuccess } from '../../../utils';
import ReactModal from 'react-modal';
import examinationIcon from '../../../assets/images/examinationIcon.svg';
import ReactDatePicker from 'react-datepicker';
import { Spinner } from '../../atoms';
import { ScheduleConfirmationModal } from '../schedule-confirmation-modal/confirmation-modal';

const formatDate = (dateString) => {
  const date = moment(dateString, 'YYYY-MM-DD');
  const formattedDate = date.format('MMMM D, YYYY');
  return formattedDate;
};

const formatDateFunction = (dateString) => {
  return moment(dateString).format('MM/DD/YYYY');
};

const formatDatePicker = (dateString) => {
  if (dateString) {
    const dateParts = dateString.split('-');
    const formattedDate = `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
    return formattedDate;
  }
};

const convertToISO = (dateString) => {
  return moment(dateString).format('YYYY-MM-DD');
};

const BusinessDay = ({ addHoliday }) => {
  const ref = useRef(null);

  useImperativeHandle(ref, () => ({
    focus: () => ref.current.focus(), // Expose a focus function
  }));

  const handleUpdateHoliday = useUpdateHoliday();
  const handleDeleteHoliday = useDeleteHoliday();
  const { handleFetchAppointmentsCount } = useAppointmentsCountForHoliday();

  const { data, refetch, loading } = useGetHoliday();
  const [isLoading, setIsLoading] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    control,
    formState: { errors, isValid, isDirty },
  } = useForm({
    resolver: yupResolver(holidayFormSchema),
  });
  const [holidayModalIsOpen, setHolidayModalIsOpen] = useState(false);
  const [holidayList, setHolidayList] = useState([]);
  const [modalValue, setModalValue] = useState(null);
  const [error, setError] = useState('');
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const [servicePayload, setServicePayload] = useState({});
  const openConfirmationModal = (data) => {
    setSelectedData(data);
    setConfirmationModal(true);
  };

  const closeConfirmationModal = () => {
    setConfirmationModal(false);
  };

  const [apptCount, setApptCount] = useState(0);
  const [scheduleConfirmationModal, setScheduleConfirmationModal] =
    useState(false);

  const openScheduleConfirmationModal = (count) => {
    setApptCount(count);
    setScheduleConfirmationModal(true);
  };

  const closeScheduleConfirmationModal = () => {
    setScheduleConfirmationModal(false);
  };

  const updateHoliday = async (payload) => {
    setIsLoading(true);
    // Update the holiday
    const response = await handleUpdateHoliday(payload ?? servicePayload);
    if (response?.data?.updateHoliday) {
      showSuccess('Holiday updated successfully');
      refetch();
      closeHolidayModal();
      reset();
      setIsLoading(false);
    } else {
      setIsLoading(false);
      showError(response);
    }
  };

  const confirmationCallback = (confirm) => {
    if (!confirm) {
      // closeHolidayModal();
      return;
    }
    updateHoliday();
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    const payload = {
      startDate: convertToISO(data?.startDate),
      endDate: convertToISO(data?.endDate),
      title: data?.title,
      holidayUid: modalValue?.uid,
    };

    setServicePayload(payload);

    try {
      // Check for appointments during the holiday period
      const countResponse = await handleFetchAppointmentsCount(payload);
      setIsLoading(false);
      const appointmentCount =
        countResponse?.data?.appointmentsForHoliday?.appointmentsCount ?? 0; //Enbale the following when get response from API
      // const appointmentCount = 1;
      if (appointmentCount > 0) {
        // Opening confirmation modal if there is any holiday.
        openScheduleConfirmationModal(appointmentCount);
        return;
      }

      // Create the holiday
      updateHoliday(payload);
    } catch (error) {
      console.error('Error during holiday creation:', error);
      setIsLoading(false);
      showError(error);
    }
  };

  const deleteHoliday = async () => {
    setIsLoading(true);
    const payload = {
      holidayUid: selectedData?.uid,
    };
    const response = await handleDeleteHoliday(payload);
    if (response && response?.data?.removeHoliday !== null) {
      showSuccess('Deleted Successfully');
      closeConfirmationModal();
      refetch();
      setIsLoading(false);
    } else {
      setIsLoading(false);
      showError('Something went wrong');
      setError('Something went wrong!!');
    }
  };

  const OpenHolidayModal = (data) => {
    reset();
    setModalValue(data);
    setHolidayModalIsOpen(true);
  };
  const closeHolidayModal = () => {
    setModalValue(null);
    setHolidayModalIsOpen(false);
  };

  useEffect(() => {
    if (data?.holidays?.nodes) {
      setHolidayList(data?.holidays?.nodes);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [addHoliday]);

  useEffect(() => {
    if (modalValue?.startDate) {
      const formattedStartDate = formatDatePicker(modalValue?.startDate);
      const formattedEndDate = formatDatePicker(modalValue?.endDate);
      setValue('title', modalValue?.title);
      setValue('startDate', formattedStartDate);
      setValue('endDate', formattedEndDate);
    }
  }, [modalValue]);

  useEffect(() => {
    const title = watch('title');
    const startingDate = watch('startDate');
    const endingDate = watch('endDate');
    if (
      title === modalValue?.title &&
      formatDateFunction(startingDate) ===
        formatDatePicker(modalValue?.startDate) &&
      endingDate === formatDatePicker(modalValue?.endDate)
    ) {
      setFormChanged(false);
    } else {
      setFormChanged(true);
    }
  }, [watch('title'), watch('endDate'), watch('startDate')]);

  return (
    <>
      {(loading || isLoading) && <Spinner isBlock={false} />}
      <div>
        <section className="mt-6">
          <h2 className="font-bold text-base text-gray-dark text-left">
            Manage Time Off
          </h2>
          <div className="mt-4 grid gap-3 overflow-y-auto height-mobileview">
            {holidayList && holidayList?.length ? (
              holidayList?.map((data, index) => {
                const endDate = new Date(data.endDate).setHours(0, 0, 0, 0);
                const startDate = new Date(data.startDate).setHours(0, 0, 0, 0);
                const currentDate = new Date().setHours(0, 0, 0, 0);
                return (
                  <div
                    key={index}
                    className="text-gray-dark cursor-pointer justify-between hover:bg-gray-lightHover bg-white-main shadow-items table-list-item flex gap-4 p-4 rounded bottom-1 border-gray-light w-full lg:max-w-555"
                  >
                    <div className="flex items-center md:gap-8 gap-4 font-semibold text-xs">
                      <p className=" md:min-w-40 w-25">{data?.title}</p>
                      <p className=" border-l border-gray-info pl-4">
                        {data?.startDate === data?.endDate
                          ? formatDate(data?.startDate)
                          : `${formatDate(data?.startDate)?.split(',')[0]} -
                          ${formatDate(data?.endDate)}`}
                      </p>
                    </div>
                    {currentDate <= endDate && (
                      <div className="flex gap-2 items-start">
                        <div
                          onClick={() =>
                            currentDate > data?.endDate
                              ? ''
                              : OpenHolidayModal(data)
                          }
                        >
                          <EditIcon width={20} height={20} />
                        </div>

                        <img
                          className="min-w-r10"
                          src={trash}
                          alt="icon"
                          onClick={() => openConfirmationModal(data)}
                        />
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <div className="grid place-content-center h-50">
                <p className="font-semibold">No Day Offs/Holidays Added</p>
              </div>
            )}
          </div>
          <ReactModal
            isOpen={holidayModalIsOpen}
            onRequestClose={closeHolidayModal}
            className={'md:w-120 w-5/6 bg-white-main'}
            style={{
              overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 31,
                backdropFilter: 'blur(2px)',
              },
              content: {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',

                border: '1px solid #ccc',
                backgroundColor: '#fff',
                // overflow: 'auto',
                WebkitOverflowScrolling: 'touch',
                borderRadius: '4px',
                outline: 'none',
                padding: '20px',
              },
            }}
          >
            <span
              className=" absolute right-4 top-2 font-semibold cursor-pointer"
              onClick={closeHolidayModal}
            >
              &#x2715;
            </span>
            <div className=" flex flex-col">
              <h2 className=" font-bold md:text-2xl text-lg  text-gray-dark">
                Edit Day off/Holidays
              </h2>
              <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                <label className="text-sm  text-gray-dark">Title</label>
                <input
                  type="text"
                  className="w-full h-10 text-xs font-medium rounded-lg border border-gray-light console-input p-2"
                  placeholder="Title"
                  {...register('title')}
                />
                {errors && errors?.title && (
                  <p className="text-red-drk text-r0.6875 ">
                    {errors?.title?.message}
                  </p>
                )}
                <div className="grid grid-cols-2 gap-4 mt-4">
                  <div className="relative">
                    <label className="text-sm text-gray-dark">Start Date</label>
                    <Controller
                      name="startDate"
                      control={control}
                      defaultValue="mm/dd/yyyy" // provide a default value if necessary
                      render={({ field }) => (
                        <ReactDatePicker
                          {...field}
                          selected={field.value}
                          dateFormat="MM/dd/yyyy"
                          placeholderText="mm/dd/yyyy"
                          minDate={new Date()}
                          ref={ref}
                          autoComplete="off"
                          onKeyDown={(e) => e.preventDefault()} // Prevent keyboard input
                          className="w-full min-w-full cursor-default text-left h-10 text-xs font-medium rounded-lg border border-gray-light console-input p-2 "
                        />
                      )}
                    />
                    {errors && errors?.startDate && (
                      <p className="text-red-drk md:text-r0.6875 text-r0.625 top-16 absolute">
                        {errors?.startDate?.message}
                      </p>
                    )}
                  </div>
                  <div className="relative">
                    <label className="text-sm text-gray-dark">End Date</label>
                    <Controller
                      name="endDate"
                      control={control}
                      defaultValue="" // provide a default value if necessary
                      render={({ field }) => (
                        <ReactDatePicker
                          {...field}
                          selected={field.value}
                          dateFormat="MM/dd/yyyy"
                          placeholderText="mm/dd/yyyy"
                          ref={ref}
                          minDate={watch('startDate') || new Date()}
                          autoComplete="off"
                          onKeyDown={(e) => e.preventDefault()} // Prevent keyboard input
                          className="w-full min-w-full cursor-default text-left h-10 text-xs font-medium rounded-lg border border-gray-light console-input p-2 "
                        />
                      )}
                    />
                    {errors && errors?.endDate && (
                      <p className="text-red-drk md:text-r0.6875 text-r0.625 top-16 absolute">
                        {errors?.endDate?.message}
                      </p>
                    )}
                  </div>
                </div>
                <button
                  type="submit"
                  className={
                    !isDirty || !formChanged
                      ? 'text-gray-dark w-full p-2 mt-12 rounded-lg bg-gray-mercury'
                      : ' w-full p-2 mt-12 rounded-lg bg-primary text-white-main hover:bg-blue-hover'
                  }
                  disabled={!isDirty || !formChanged}
                >
                  Save
                </button>
                {error && <p style={{ color: 'red' }}>{error}</p>}
              </form>
            </div>
          </ReactModal>

          <ReactModal
            isOpen={confirmationModal}
            onRequestClose={closeConfirmationModal}
            className={'md:w-3/12 w-5/6 bg-white-main'}
            style={{
              overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 31,
                backdropFilter: 'blur(2px)',
              },
              content: {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                border: '1px solid #ccc',
                backgroundColor: '#fff',
                overflow: 'auto',
                WebkitOverflowScrolling: 'touch',
                borderRadius: '4px',
                outline: 'none',
                padding: '20px',
              },
            }}
          >
            <span
              className=" absolute right-4 top-2 font-semibold cursor-pointer"
              onClick={closeConfirmationModal}
            >
              &#x2715;
            </span>
            <div className="text-center mt-3 flex flex-col justify-center items-center">
              <img src={examinationIcon} />
              <p className="mt-3 font-semibold">
                Are you sure you want to delete holiday?
              </p>
            </div>
            <div className="grid grid-cols-2 gap-4  mt-8">
              <button
                className="h-10 w-full  border border-gray-dark bg-very-light text-gray-dark text-xs hover:text-gray-middle hover:border-very-dark py-2 text-center font-bold rounded-md"
                onClick={closeConfirmationModal}
              >
                Cancel
              </button>
              <button
                className="h-10 w-full border  bg-primary text-white-main  text-xs hover:bg-blue-hover py-2 text-center font-bold rounded-md"
                onClick={deleteHoliday}
              >
                Delete
              </button>
            </div>
          </ReactModal>
          {/* Apoointment Confirmation Modal */}
          <ScheduleConfirmationModal
            apptCount={apptCount}
            scheduleConfirmationModal={scheduleConfirmationModal}
            closeScheduleConfirmationModal={closeScheduleConfirmationModal}
            confirmationCallback={confirmationCallback}
            actionType="holiday"
          />
        </section>
      </div>
    </>
  );
};

export default BusinessDay;
