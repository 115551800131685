import React from 'react';

export const CloseIcon = (props) => {
  return (
    <svg
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0204 9.9535L6.96638 5.89946L11.0204 1.8454C11.2873 1.57854 11.2873 1.14588 11.0204 0.879012C10.7536 0.612147 10.3209 0.612148 10.054 0.879014L5.99997 4.93309L1.94593 0.879055C1.94593 0.879052 1.94593 0.87905 1.94592 0.879047C1.67908 0.612153 1.24642 0.612162 0.979554 0.879024C0.712689 1.14589 0.712688 1.57855 0.979553 1.84542L5.03362 5.89948L0.979544 9.95356C0.712678 10.2204 0.712676 10.6531 0.979541 10.92C1.24641 11.1868 1.67907 11.1868 1.94593 10.92L6.00001 6.86587L10.0541 10.9199C10.3209 11.1868 10.7536 11.1868 11.0205 10.9199C11.2873 10.6531 11.2873 10.2204 11.0204 9.9535Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.2"
      />
    </svg>
  );
};
