import React from 'react';

export const Business = (props) => {
  const { color } = props;
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="18"
        viewBox="0 0 21 18"
        fill="none"
        {...props}
      >
        <path
          d="M18.95 17.3479H2.55C2.07261 17.3479 1.61477 17.1582 1.27721 16.8207C0.939642 16.4831 0.75 16.0253 0.75 15.5479L0.75 3.51387C0.75 3.03648 0.939642 2.57864 1.27721 2.24108C1.61477 1.90351 2.07261 1.71387 2.55 1.71387H18.95C19.4274 1.71387 19.8852 1.90351 20.2228 2.24108C20.5604 2.57864 20.75 3.03648 20.75 3.51387V15.5499C20.7495 16.0269 20.5596 16.4842 20.2221 16.8214C19.8846 17.1585 19.427 17.3479 18.95 17.3479ZM2.55 2.71387C2.33783 2.71387 2.13434 2.79815 1.98431 2.94818C1.83429 3.09821 1.75 3.30169 1.75 3.51387V15.5499C1.75 15.762 1.83429 15.9655 1.98431 16.1156C2.13434 16.2656 2.33783 16.3499 2.55 16.3499H18.95C19.1622 16.3499 19.3657 16.2656 19.5157 16.1156C19.6657 15.9655 19.75 15.762 19.75 15.5499V3.51387C19.75 3.30169 19.6657 3.09821 19.5157 2.94818C19.3657 2.79815 19.1622 2.71387 18.95 2.71387H2.55Z"
          fill={color}
        />
        <path
          d="M3.9043 3.426C3.77169 3.426 3.64451 3.37332 3.55074 3.27955C3.45698 3.18579 3.4043 3.05861 3.4043 2.926V0.5C3.4043 0.367392 3.45698 0.240215 3.55074 0.146447C3.64451 0.0526784 3.77169 0 3.9043 0C4.03691 0 4.16408 0.0526784 4.25785 0.146447C4.35162 0.240215 4.4043 0.367392 4.4043 0.5V2.926C4.4043 3.05861 4.35162 3.18579 4.25785 3.27955C4.16408 3.37332 4.03691 3.426 3.9043 3.426Z"
          fill={color}
        />
        <path
          d="M17.2441 3.426C17.1115 3.426 16.9844 3.37332 16.8906 3.27955C16.7968 3.18579 16.7441 3.05861 16.7441 2.926V0.5C16.7441 0.367392 16.7968 0.240215 16.8906 0.146447C16.9844 0.0526784 17.1115 0 17.2441 0C17.3767 0 17.5039 0.0526784 17.5977 0.146447C17.6915 0.240215 17.7441 0.367392 17.7441 0.5V2.926C17.7441 3.05861 17.6915 3.18579 17.5977 3.27955C17.5039 3.37332 17.3767 3.426 17.2441 3.426Z"
          fill={color}
        />
        <path
          d="M20.2059 6.33594H1.29395C1.16134 6.33594 1.03416 6.28326 0.940392 6.18949C0.846624 6.09572 0.793945 5.96855 0.793945 5.83594C0.793945 5.70333 0.846624 5.57615 0.940392 5.48238C1.03416 5.38862 1.16134 5.33594 1.29395 5.33594H20.2059C20.3386 5.33594 20.4657 5.38862 20.5595 5.48238C20.6533 5.57615 20.7059 5.70333 20.7059 5.83594C20.7059 5.96855 20.6533 6.09572 20.5595 6.18949C20.4657 6.28326 20.3386 6.33594 20.2059 6.33594Z"
          fill={color}
        />
        <path
          d="M16.3495 13.6882C16.2837 13.6881 16.2185 13.6749 16.1578 13.6495C16.097 13.6241 16.0419 13.587 15.9955 13.5402L12.5255 10.0802C12.4317 9.98633 12.3789 9.85899 12.3789 9.72622C12.3789 9.59344 12.4317 9.4661 12.5255 9.37222C12.6194 9.27833 12.7468 9.22559 12.8795 9.22559C13.0123 9.22559 13.1397 9.27833 13.2335 9.37222L16.6935 12.8342C16.74 12.8805 16.7769 12.9355 16.8021 12.9961C16.8273 13.0567 16.8403 13.1216 16.8403 13.1872C16.8403 13.2528 16.8273 13.3178 16.8021 13.3784C16.7769 13.4389 16.74 13.4939 16.6935 13.5402C16.6029 13.633 16.4792 13.6862 16.3495 13.6882Z"
          fill={color}
        />
        <path
          d="M12.8799 13.6878C12.7813 13.6871 12.6852 13.6573 12.6034 13.6022C12.5216 13.5471 12.4579 13.4692 12.4201 13.3781C12.3823 13.287 12.3722 13.1868 12.3911 13.09C12.4099 12.9932 12.4568 12.9041 12.5259 12.8338L15.9879 9.37184C16.0341 9.32416 16.0894 9.28615 16.1504 9.26003C16.2114 9.23392 16.2771 9.22022 16.3434 9.21974C16.4098 9.21926 16.4757 9.232 16.5371 9.25723C16.5985 9.28246 16.6542 9.31966 16.7011 9.36667C16.748 9.41368 16.7851 9.46956 16.8101 9.53104C16.8352 9.59252 16.8477 9.65838 16.847 9.72476C16.8464 9.79115 16.8325 9.85674 16.8062 9.9177C16.7799 9.97867 16.7418 10.0338 16.6939 10.0798L13.2339 13.5398C13.1876 13.5866 13.1325 13.6238 13.0717 13.6492C13.011 13.6745 12.9458 13.6877 12.8799 13.6878Z"
          fill={color}
        />
      </svg>
    </div>
  );
};
Business.defaultProps = {
  color: '#585858',
};
