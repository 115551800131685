import { gql, useMutation } from '@apollo/client';

// Define the GraphQL mutation
const CANCEL_SUBSCRIPTION = gql`
  mutation CancelSellerSubscription($sellerSubscriptionUid: String!) {
    cancelSellerSubscription(sellerSubscriptionUid: $sellerSubscriptionUid) {
      status
    }
  }
`;

// Create the custom hook
export const useCancelSubscription = () => {
  const [cancelSubscriptionMutation, { data, loading, error }] =
    useMutation(CANCEL_SUBSCRIPTION);

  // Function to trigger the mutation
  const cancelSellerSubscription = async (sellerSubscriptionUid) => {
    try {
      const response = await cancelSubscriptionMutation({
        variables: {
          sellerSubscriptionUid: sellerSubscriptionUid,
        },
      });
      return response.data.cancelSellerSubscription.status;
    } catch (error) {
      console.error('Error cancelling seller subscription:', error);
      throw new Error('Failed to cancel seller subscription');
    }
  };

  return {
    cancelSellerSubscription,
    data,
    loading,
    error,
  };
};
