import React from 'react';

export const CloudUploadIcon = (props) => {
  return (
    <svg
      width="23"
      height="21"
      viewBox="0 0 23 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.3121 14.3616H17.7289C20.2017 14.3616 22.2248 13.0484 22.2248 10.603C22.2248 8.15773 19.842 6.94024 17.9088 6.84448C17.5091 3.02027 14.7167 0.694092 11.4347 0.694092C8.33251 0.694092 6.33454 2.75276 5.67993 4.79435C2.9824 5.05061 0.644531 6.76715 0.644531 9.57798C0.644531 12.3888 3.07232 14.3616 6.03961 14.3616H8.55731"
        stroke="#2F80ED"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3133 11.1679L11.436 8.29053L8.55859 11.1679M11.436 19.8189V9.00987"
        stroke="#2F80ED"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
