const getCanvasSize = (img, imgAspect, targetAspect) => {
  if (imgAspect > targetAspect) {
    return {
      width: img.naturalWidth,
      height: img.naturalWidth * (1 / targetAspect),
    };
  }

  return {
    width: img.naturalHeight * targetAspect,
    height: img.naturalHeight,
  };
};

export const imageInAspectAsync = (imageSrc, targetAspect, fillStyle) =>
  new Promise((resolve, reject) => {
    const tempImage = new Image();

    tempImage.onerror = reject;

    tempImage.onload = ({ target: img }) => {
      const imgAspect = img.naturalWidth / img.naturalHeight;
      if (imgAspect === targetAspect) {
        resolve(imageSrc);
      }

      try {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const { width, height } = getCanvasSize(img, imgAspect, targetAspect);

        canvas.width = width;
        canvas.height = height;

        if (fillStyle) {
          ctx.fillStyle = fillStyle;
          ctx.fillRect(0, 0, width, height);
        }

        ctx.drawImage(
          img,
          (width - img.naturalWidth) / 2,
          (height - img.naturalHeight) / 2
        );

        resolve(canvas.toDataURL());
      } catch (error) {
        reject(`Couldn't extend image aspect`);
      }
    };

    tempImage.src = imageSrc;
  });
