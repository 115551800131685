import React from 'react';

export const OnlinestoreIcon = (props) => {
  return (
    <div>
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="15"
        viewBox="0 0 17 15"
        fill="none"
      >
        <path
          d="M2.23161 3.92507C5.27197 3.92245 8.0338 3.91068 11.1513 3.92572C13.0919 3.94076 14.9501 5.28375 15.517 7.21128C15.8518 8.34961 15.723 9.61807 15.1626 10.6642C14.3859 12.1157 12.7983 13.0834 11.1212 13.0867L0.652578 13.0867C0.388426 13.1187 0.136044 13.2567 0.0405827 13.5084C-0.0914936 13.8563 0.133429 14.2865 0.495657 14.3761C0.546656 14.3885 0.600271 14.3885 0.652578 14.395C4.14867 14.395 7.64476 14.4003 11.1409 14.395C13.5457 14.3839 15.8753 12.7879 16.6894 10.4288C17.4112 8.33981 16.8541 5.87221 15.2359 4.29253C14.1583 3.23984 12.6597 2.62915 11.1598 2.61738L11.1225 2.61673L2.23226 2.61673L3.73217 1.11682L3.75767 1.09001C3.7786 1.06516 3.78448 1.05928 3.80344 1.03247C4.05321 0.680704 3.83744 0.0863617 3.3791 0.0085545C3.1751 -0.0260991 2.95999 0.0464775 2.80699 0.190977L0.189659 2.80831C0.107275 2.89527 0.0922375 2.92665 0.0628147 2.98746C-0.043108 3.2078 -0.0136852 3.48569 0.140621 3.67922C0.160891 3.70472 0.167429 3.70995 0.189659 3.73415L2.80699 6.35147C2.94168 6.47832 3.11822 6.54697 3.30652 6.54174C3.71517 6.50774 4.02183 6.0579 3.88713 5.67279C3.86294 5.60348 3.82698 5.53809 3.78121 5.48056C3.76094 5.45506 3.75506 5.44983 3.73217 5.42563L2.23161 3.92507Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};
