import { storageService } from './storage-service';
import { LOCAL_STORAGE_KEYS } from '../constants';

class SessionCacheService {
  _token;

  constructor() {
    this._initToken();
  }

  get token() {
    return this._token;
  }

  get hasToken() {
    return !!this._token;
  }

  setToken(token) {
    storageService.setItem(LOCAL_STORAGE_KEYS.token, token);
    this._updateServiceState(token);
  }

  removeToken() {
    storageService.removeItem(LOCAL_STORAGE_KEYS.token);
    this._updateServiceState();
  }

  _updateServiceState(token) {
    this._token = token;
  }

  _initToken() {
    const params = new URLSearchParams(window.location.hash?.replace('#', ''));
    const urlToken = params?.get('token');

    if (urlToken) {
      this.setToken(urlToken);
      this._removeTokenFromHash();
      return;
    }

    this.setToken(storageService.getItem(LOCAL_STORAGE_KEYS.token));
  }

  _removeTokenFromHash() {
    window.location.hash = window.location.hash.replace(
      `token=${this.token}`,
      ''
    );
  }
}

export const sessionCacheStorage = new SessionCacheService();
