import React, { useState, useEffect } from 'react';
import { ROUTING_CONFIG } from '../../../constants';
import {
  NavigateSchedulerLink,
  DashboardIcon,
  MenuSeparator,
  OnlinestoreIcon,
  AppointmentsIcon,
  ServicesIcon,
  CategoriesIcon,
  ClientIcon,
  SettingsIcon,
} from '../../atoms';
import { useGetSellerConsoleLink } from '../../../hooks';

const getSchedulerSideBarNav = ({ handleOnlineStoreClick }) => {
  const items = [
    {
      label: 'My Online Store',
      to: '',
      icon: <OnlinestoreIcon />,
      end: true,
      type: 'scheduler',
      external: true,
      onClick: handleOnlineStoreClick,
    },
    {
      label: 'Dashboard',
      to: ROUTING_CONFIG.schedulerDashboard,
      icon: <DashboardIcon />,
      end: true,
      type: 'scheduler',
    },
    {
      label: 'Appointments',
      to: ROUTING_CONFIG.appointments,
      icon: <AppointmentsIcon />,
      end: true,
      type: 'scheduler',
    },
    {
      label: 'Services',
      to: ROUTING_CONFIG.service,
      icon: <ServicesIcon />,
      end: true,
      type: 'scheduler',
    },
    {
      label: 'Categories',
      to: ROUTING_CONFIG.schedulerCategories,
      icon: <CategoriesIcon />,
      end: true,
      type: 'scheduler',
    },
    {
      label: 'Clients',
      to: ROUTING_CONFIG.clients,
      icon: <ClientIcon />,
      end: true,
      type: 'scheduler',
    },
    {
      label: 'Manage Schedule',
      to: ROUTING_CONFIG.manageSchedule,
      icon: <SettingsIcon />,
      end: true,
      type: 'scheduler',
    },
    {
      label: 'Manage Subscription',
      to: ROUTING_CONFIG.manageSubscription,
      icon: <SettingsIcon />,
      end: true,
      type: 'scheduler',
    },
  ];
  return items;
};

export const SchedulerNavigation = () => {
  const [isSubscribed, setIsSubscribed] = useState(false); // Changed default value to false
  const { refetch } = useGetSellerConsoleLink();

  useEffect(() => {
    const timeout = setTimeout(() => {
      const subscriptionStatus =
        localStorage.getItem('isSubscribed') === 'true';
      setIsSubscribed(subscriptionStatus);
    }, 1000); // Adjust the timeout duration as needed (in milliseconds)
    return () => clearTimeout(timeout); // Cleanup function to clear the timeout
  }, [localStorage.getItem('isSubscribed')]);

  const handleOnlineStoreClick = async (e) => {
    e.preventDefault();
    const { data } = await refetch();
    window.location.href = data?.sellerConsoleLink?.url;
  };

  const mainSideBarNav = getSchedulerSideBarNav({
    handleOnlineStoreClick,
  });

  return (
    <ul
      className="-mx-6"
      style={{
        pointerEvents: isSubscribed ? 'auto' : 'none',
        opacity: isSubscribed ? 1 : 0.7,
      }}
    >
      {mainSideBarNav.map(
        ({ to, label, icon, end, separate, type, onClick }, index) => (
          <React.Fragment key={index}>
            {!!separate && <MenuSeparator />}
            <li className="my-4">
              <NavigateSchedulerLink
                to={to}
                label={label}
                icon={icon}
                end={end}
                type={type}
                onClick={onClick}
              />
            </li>
          </React.Fragment>
        )
      )}
    </ul>
  );
};
