import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import {
  SchedulerCategoriesPage,
  SchedulerServicePage,
  ManageSchedulePage,
  ManageSubscription,
  Appointments,
  ClientsPage,
  LandingPage,
} from '../pages';
import { PrivateRoute } from '../components/molecules';

import PaymentSuccessfull from '../pages/payment-successfull/payment-successfull';
import { ROUTING_CONFIG } from '../constants';
import AddService from '../pages/scheduler-service/add-service';
import ServiceDetails from '../pages/scheduler-service/service-details';
import ClientDetails from '../pages/scheduler-clients/client-details';
import AppointmentDetails from '../pages/scheduler-appointments/appointment.details';

const ExternalRedirect = ({ url }) => {
  React.useEffect(() => {
    window.location.href = url;
  }, [url]);
  return null;
};

export const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <ExternalRedirect url={process.env.REACT_APP_ONLINE_STORE_URL} />
        }
      />
      <Route
        path={ROUTING_CONFIG.schedulerDashboard}
        element={
          <PrivateRoute>
            <LandingPage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTING_CONFIG.appointments}
        element={
          <PrivateRoute>
            <Appointments />
          </PrivateRoute>
        }
      />
      <Route
        path={'/appointment-details'}
        element={
          <PrivateRoute>
            <AppointmentDetails />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTING_CONFIG.clients}
        element={
          <PrivateRoute>
            <ClientsPage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTING_CONFIG.schedulerCategories}
        element={
          <PrivateRoute>
            <SchedulerCategoriesPage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTING_CONFIG.service}
        element={
          <PrivateRoute>
            <SchedulerServicePage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTING_CONFIG.manageSchedule}
        element={
          <PrivateRoute>
            <ManageSchedulePage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTING_CONFIG.manageSubscription}
        element={
          <PrivateRoute>
            <ManageSubscription />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTING_CONFIG.paymentSuccess}
        element={
          <PrivateRoute>
            <PaymentSuccessfull />
          </PrivateRoute>
        }
      />
      <Route
        path={'/add-service'}
        element={
          <PrivateRoute>
            <AddService />
          </PrivateRoute>
        }
      />
      <Route
        path={'/service-details'}
        element={
          <PrivateRoute>
            <ServiceDetails />
          </PrivateRoute>
        }
      />
      <Route
        path={'/client-details'}
        element={
          <PrivateRoute>
            <ClientDetails />
          </PrivateRoute>
        }
      />
      <Route
        path={'/payment-success'}
        element={
          <PrivateRoute>
            <PaymentSuccessfull />
          </PrivateRoute>
        }
      />
      <Route
        path="*"
        element={<Navigate to={ROUTING_CONFIG.schedulerDashboard} />}
      />
    </Routes>
  );
};
