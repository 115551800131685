import React from 'react';
import clsx from 'clsx';

export const Overlay = ({ className = 'bg-white-main' }) => {
  return (
    <div
      className={clsx(
        'absolute w-full h-full opacity-50 z-10 top-0 left-0',
        className
      )}
    />
  );
};
