import * as yup from 'yup';

// Define your validation schema function
export const calendarViewAppointmentBookingSchema = () => {
  return yup
    .object()
    .shape({
      client: yup.string().required('Please select a client.'),
      contact: yup.string().required('Please select a contact.'),
      service: yup.string().required('Please select a service.'),
      time: yup.string().required('Please select a time.'),
      emailNotify: yup.boolean(),
      mobileNotify: yup.boolean(),
    })
    .test(
      'notificationMethod',
      'Please select at least one notification method.',
      function (values) {
        const { emailNotify, mobileNotify } = values;
        if (!emailNotify && !mobileNotify) {
          return this.createError({
            path: 'emailNotify',
            message: 'Please select at least one notification method.',
          });
        }
        return true;
      }
    );
};
