import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';

const GET_CLIENTS = gql`
  query ListBuyers(
    $filter: BuyersFilterInput!
    $pagination: PaginationInput
    $archived: Boolean
  ) {
    buyers(pagination: $pagination, filter: $filter, archived: $archived) {
      nodes {
        uid
        formalName
        email
        phone
      }
      pagination {
        perPage
        lastPage
        nextPage
        totalNodes
        totalPages
        currentPage
      }
    }
  }
`;

export const useGetClients = (input) => {
  const { loading, error, data, refetch } = useQuery(GET_CLIENTS, {
    variables: input,
  });

  return { loading, error, data, refetch };
};
