import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import moment from 'moment';
import {
  useCancelSubscription,
  useCreateSubscriptionCheckout,
  useGetCurrentStorefrontDetails,
  useGetSubscriptionPlan,
} from '../../../hooks';
import { showError, showSuccess } from '../../../utils';
import DollarModalIcon from '../../atoms/icons/dollar-modal.icon';
import SubscriptionImg from '../../../assets/images/subs-icon.svg';
import { Spinner } from '../../atoms';
import { CancelConfirmationModal } from '../../atoms/subscription-cancel-confirmation/cancel-confirmation-modal';

const SubscriptionCheckout = ({ isSubscribed, isSessionCreated }) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  const [subscriptionPlan, setSubscriptionPlan] = useState();
  const [cancelConfirmationModal, setCancelConfirmationModal] = useState(false); // State for modal visibility

  const { loading, error, data, refetch } = useGetSubscriptionPlan();
  const { cancelSellerSubscription, loading: cancelLoading } =
    useCancelSubscription();
  const { createSubscriptionCheckout, loading: checkoutLoading } =
    useCreateSubscriptionCheckout();

  useEffect(() => {
    if (data?.subscriptionPlan) {
      setSubscriptionPlan(data?.subscriptionPlan);
    }
  }, [data]);

  const { data: storefront, refetch: storeRefetch } =
    useGetCurrentStorefrontDetails();

  useEffect(() => {
    if (storefront?.currentStorefront) {
      // Convert object to string before storing in session storage
      const serializedData = JSON.stringify(storefront.currentStorefront);
      sessionStorage.setItem('storefrontData', serializedData);
    }
  }, [storefront]);

  useEffect(() => {
    refetch();
  }, [isSessionCreated]);

  const handleOpenCancelConfirmationModal = () => {
    setCancelConfirmationModal(true);
  };

  const handleCloseCancelConfirmationModal = () => {
    setCancelConfirmationModal(false);
  };

  const confirmationCallback = async (confirmed) => {
    if (confirmed) {
      try {
        const status = await cancelSellerSubscription(
          subscriptionPlan?.activeSubscription?.uid
        );
        console.log('Subscription cancel status', status);
        showSuccess('Subscription cancelled successfully');
        refetch();
        storeRefetch();
      } catch (error) {
        showError(error.message);
      } finally {
        handleCloseCancelConfirmationModal();
      }
    } else {
      handleCloseCancelConfirmationModal();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const stripe = await stripePromise;

      if (!stripe) {
        console.error('Stripe.js failed to load.');
        return;
      }

      const payload = {
        planPriceId: subscriptionPlan?.planPriceId,
        backLinks: {
          successBackUrl: `${window.location.origin}/payment-success`,
          cancelBackUrl: `${window.location.origin}/scheduler-dashboard`,
        },
      };

      const response = await createSubscriptionCheckout({ variables: payload });
      const sessionId =
        response?.data?.createSubscriptionCheckout?.remoteSession?.id;

      if (!sessionId) {
        console.error('Failed to create Stripe session.');
        return;
      }

      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        console.error('Stripe redirect to checkout failed:', error);
      }
    } catch (error) {
      console.error('Error creating subscription:', error);
    }
  };

  return (
    <>
      {(loading || cancelLoading || checkoutLoading) && <Spinner />}
      <section className="grid place-content-center p-4 md:mt-5 mt-4 h-headScreen">
        <div>
          <div className=" relative bg-white-main rounded-xl  max-w-xl max-h-80 md:max-h-fit overflow-auto md:min-w-144 min-w-320 wrp-box">
            <div className="flex justify-start py-1 px-4 items-center gap-4  md:mb-8 mb-6 subscription-head">
              {/* <DollarModalIcon /> */}
              <img src={SubscriptionImg} alt="icon" className="" />
              <div>
                <h4 className="font-bold md:text-3xl text-xl">
                  {subscriptionPlan?.name}
                </h4>
                <h4 className="font-bold md:text-3xl text-xl mt-1">
                  $
                  {subscriptionPlan?.activeSubscription?.active
                    ? subscriptionPlan?.activeSubscription
                        ?.subscriptionPlanPrice?.price
                    : subscriptionPlan?.price}
                </h4>
              </div>
            </div>
            <div className="px-8 pb-8 relative md:pt-0 pt-2">
              {subscriptionPlan?.activeSubscription &&
                subscriptionPlan?.activeSubscription?.currentPeriodEndedAt && (
                  <>
                    <div className="text-center mb-4">
                      <p className="inline-flex my-0 mx-auto right-4 md:-top-12 -top-8 bg-green-cover px-4 py-1 text-white-main rounded-10 font-bold text-sm">
                        Valid Until:{' '}
                        {moment(
                          subscriptionPlan?.activeSubscription
                            ?.currentPeriodEndedAt
                        ).format('MMMM D, YYYY')}
                      </p>
                    </div>
                  </>
                )}
              <ul className="html-content">
                {subscriptionPlan?.description && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: subscriptionPlan.description.replace(
                        /\n/g,
                        '<br/>'
                      ),
                    }}
                  />
                )}
              </ul>
              <div className="flex md:gap-10 justify-center mt-4 md:mt-8 md:flex-row flex-col gap-5">
                {/* {isSubscribed === false && (
                  <button
                    className="md:w-full rounded p-3 border-gray-dark border hover:bg-gray-hover text-xs font-bold"
                    onClick={handleOpenCancelConfirmationModal} // Open modal on click
                  >
                    Cancel
                  </button>
                )} */}
                {subscriptionPlan?.activeSubscription ? (
                  !subscriptionPlan?.activeSubscription?.active ? (
                    <button
                      className="md:w-40 p-3 rounded bg-primary text-white-main hover:bg-blue-hover text-xs font-bold"
                      onClick={handleSubmit}
                    >
                      Subscribe
                    </button>
                  ) : (
                    <button
                      className=" px-6 py-1 rounded bg-red-cover text-white-main"
                      onClick={handleOpenCancelConfirmationModal} // Open modal on click
                      disabled={loading}
                    >
                      Cancel
                    </button>
                  )
                ) : (
                  <button
                    // className="md:w-full p-3 rounded bg-primary text-white-main hover:bg-blue-hover text-xs font-bold border-primary border"
                    className="md:w-40 p-3 rounded bg-primary text-white-main hover:bg-blue-hover text-xs font-bold"
                    onClick={handleSubmit}
                  >
                    Subscribe
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Render CancelConfirmationModal with props */}
      <CancelConfirmationModal
        cancelConfirmationModal={cancelConfirmationModal}
        closeCancelConfirmationModal={handleCloseCancelConfirmationModal}
        confirmationCallback={confirmationCallback} // Pass confirmationCallback function
      />
    </>
  );
};

export default SubscriptionCheckout;
