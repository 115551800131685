import * as yup from 'yup';

const imageSizeLimit = 5 * 1024 * 1024;

export const categoryFormSchema = yup.object().shape({
  // fileInput: yup
  //   .mixed()
  //   .test('fileSize', 'Image should be less than 5MB in size', (value) => {
  //     console.log('value === ', value);
  //     if (!value) return true; // if no file is selected, allow the form submission
  //     return value.size <= imageSizeLimit;
  //   })
  //   .test(
  //     'fileType',
  //     'Invalid file type. Only images are allowed.',
  //     (value) => {
  //       if (!value) return true; // if no file is selected, allow the form submission
  //       return ['image/jpeg', 'image/png', 'image/gif'].includes(value.type);
  //     }
  //   ),
  categoryName: yup
    .string()
    .max(50, 'Please enter maximum 50 letters')
    .required('Please enter category name')
    .matches(
      /^[a-zA-Z0-9][a-zA-Z0-9\s]*$/,
      'Please avoid special characters & leading space'
    ),
});
