import { useLazyQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';

const APPOINTMENTS_FOR_HOLIDAY = gql`
  query AppointmentsForHoliday(
    $holidayUid: String!
    $title: String!
    $startDate: ISO8601Date!
    $endDate: ISO8601Date!
  ) {
    appointmentsForHoliday(
      holidayUid: $holidayUid
      input: { title: $title, startDate: $startDate, endDate: $endDate }
    ) {
      appointmentsCount
    }
  }
`;

export const useAppointmentsCountForHoliday = () => {
  const [fetchAppointmentsCount, { data, loading, error }] = useLazyQuery(
    APPOINTMENTS_FOR_HOLIDAY
  );

  const handleFetchAppointmentsCount = async ({
    holidayUid,
    title,
    startDate,
    endDate,
  }) => {
    try {
      const response = await fetchAppointmentsCount({
        variables: {
          holidayUid,
          title,
          startDate,
          endDate,
        },
      });

      return response;
    } catch (err) {
      console.log('Error creating holiday:', err);
      // Handle the error appropriately (e.g., display a user-friendly message)

      // Check for GraphQL errors
      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        // Handle GraphQL errors
        return err.graphQLErrors[0]?.message;
      }

      // Check for network errors
      if (err.networkError) {
        // Handle network errors
        console.error('Network error:', err.networkError);
        // You can choose to handle network errors differently
        // For instance, you can throw a custom error or return a specific response
      }

      return null; // Or throw a custom error for further handling
    }
  };

  return {
    handleFetchAppointmentsCount,
    data,
    loading,
    error,
  };
};
