import * as yup from 'yup';

export const editFormSchema = yup.object().shape({
  shifts: yup.array().of(
    yup.object().shape({
      startTime: yup.string().required('Start time is required'),
      endTime: yup.string().required('End time is required'),
    })
  ),
});
