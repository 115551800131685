import React from 'react';

const PlusIcon = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          d="M5.16663 5.16667V0.166668H6.83329V5.16667H11.8333V6.83333H6.83329V11.8333H5.16663V6.83333H0.166626V5.16667H5.16663Z"
          fill="#585858"
        />
      </svg>
    </div>
  );
};

export default PlusIcon;
