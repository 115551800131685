import * as yup from 'yup';

export const holidayFormSchema = yup.object().shape({
  title: yup
    .string()
    .max(50, 'Please enter less than 50 letters')
    .required('Please enter title')
    .matches(
      /^[a-zA-Z0-9][a-zA-Z0-9\s]*$/,
      'Please avoid special characters & leading space'
    ),
  startDate: yup.string().nullable().required('Please select start date'),
  endDate: yup
    .string()
    .nullable()
    .required('Please select end date')
    .test(
      'is-greater',
      'End date must be greater than or equal to start date',
      function (value) {
        const { startDate } = this.parent; // Accessing the value of startDate
        if (!startDate || !value) return true; // Skip validation if either startDate or endDate is not provided
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(value);
        return endDateObj >= startDateObj; // Returns true if endDate is greater than startDate
      }
    ),
});
