import React from 'react';

export const SpinnerIcon = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle cx="78" cy="50" fill="#97c3ff" r="6">
        <animate
          attributeName="r"
          values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996"
          keyTimes="0;0.1;0.2;0.3;1"
          dur="1.075268817204301s"
          repeatCount="indefinite"
          begin="-0.9677419354838709s"
        />
        <animate
          attributeName="fill"
          values="#97c3ff;#97c3ff;#2f80ed;#97c3ff;#97c3ff"
          repeatCount="indefinite"
          keyTimes="0;0.1;0.2;0.3;1"
          dur="1.075268817204301s"
          begin="-0.9677419354838709s"
        />
      </circle>
      <circle
        cx="72.65247584249853"
        cy="66.45798706418924"
        fill="#97c3ff"
        r="6"
      >
        <animate
          attributeName="r"
          values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996"
          keyTimes="0;0.1;0.2;0.3;1"
          dur="1.075268817204301s"
          repeatCount="indefinite"
          begin="-0.8602150537634408s"
        />
        <animate
          attributeName="fill"
          values="#97c3ff;#97c3ff;#2f80ed;#97c3ff;#97c3ff"
          repeatCount="indefinite"
          keyTimes="0;0.1;0.2;0.3;1"
          dur="1.075268817204301s"
          begin="-0.8602150537634408s"
        />
      </circle>
      <circle cx="58.65247584249853" cy="76.6295824562643" fill="#97c3ff" r="6">
        <animate
          attributeName="r"
          values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996"
          keyTimes="0;0.1;0.2;0.3;1"
          dur="1.075268817204301s"
          repeatCount="indefinite"
          begin="-0.7526881720430108s"
        />
        <animate
          attributeName="fill"
          values="#97c3ff;#97c3ff;#2f80ed;#97c3ff;#97c3ff"
          repeatCount="indefinite"
          keyTimes="0;0.1;0.2;0.3;1"
          dur="1.075268817204301s"
          begin="-0.7526881720430108s"
        />
      </circle>
      <circle
        cx="41.347524157501475"
        cy="76.6295824562643"
        fill="#97c3ff"
        r="6"
      >
        <animate
          attributeName="r"
          values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996"
          keyTimes="0;0.1;0.2;0.3;1"
          dur="1.075268817204301s"
          repeatCount="indefinite"
          begin="-0.6451612903225806s"
        />
        <animate
          attributeName="fill"
          values="#97c3ff;#97c3ff;#2f80ed;#97c3ff;#97c3ff"
          repeatCount="indefinite"
          keyTimes="0;0.1;0.2;0.3;1"
          dur="1.075268817204301s"
          begin="-0.6451612903225806s"
        />
      </circle>
      <circle
        cx="27.347524157501475"
        cy="66.45798706418925"
        fill="#97c3ff"
        r="6"
      >
        <animate
          attributeName="r"
          values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996"
          keyTimes="0;0.1;0.2;0.3;1"
          dur="1.075268817204301s"
          repeatCount="indefinite"
          begin="-0.5376344086021505s"
        />
        <animate
          attributeName="fill"
          values="#97c3ff;#97c3ff;#2f80ed;#97c3ff;#97c3ff"
          repeatCount="indefinite"
          keyTimes="0;0.1;0.2;0.3;1"
          dur="1.075268817204301s"
          begin="-0.5376344086021505s"
        />
      </circle>
      <circle cx="22" cy="50" fill="#97c3ff" r="6">
        <animate
          attributeName="r"
          values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996"
          keyTimes="0;0.1;0.2;0.3;1"
          dur="1.075268817204301s"
          repeatCount="indefinite"
          begin="-0.4301075268817204s"
        />
        <animate
          attributeName="fill"
          values="#97c3ff;#97c3ff;#2f80ed;#97c3ff;#97c3ff"
          repeatCount="indefinite"
          keyTimes="0;0.1;0.2;0.3;1"
          dur="1.075268817204301s"
          begin="-0.4301075268817204s"
        />
      </circle>
      <circle
        cx="27.34752415750147"
        cy="33.54201293581076"
        fill="#97c3ff"
        r="6"
      >
        <animate
          attributeName="r"
          values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996"
          keyTimes="0;0.1;0.2;0.3;1"
          dur="1.075268817204301s"
          repeatCount="indefinite"
          begin="-0.3225806451612903s"
        />
        <animate
          attributeName="fill"
          values="#97c3ff;#97c3ff;#2f80ed;#97c3ff;#97c3ff"
          repeatCount="indefinite"
          keyTimes="0;0.1;0.2;0.3;1"
          dur="1.075268817204301s"
          begin="-0.3225806451612903s"
        />
      </circle>
      <circle cx="41.34752415750147" cy="23.3704175437357" fill="#97c3ff" r="6">
        <animate
          attributeName="r"
          values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996"
          keyTimes="0;0.1;0.2;0.3;1"
          dur="1.075268817204301s"
          repeatCount="indefinite"
          begin="-0.2150537634408602s"
        />
        <animate
          attributeName="fill"
          values="#97c3ff;#97c3ff;#2f80ed;#97c3ff;#97c3ff"
          repeatCount="indefinite"
          keyTimes="0;0.1;0.2;0.3;1"
          dur="1.075268817204301s"
          begin="-0.2150537634408602s"
        />
      </circle>
      <circle
        cx="58.65247584249852"
        cy="23.370417543735698"
        fill="#97c3ff"
        r="6"
      >
        <animate
          attributeName="r"
          values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996"
          keyTimes="0;0.1;0.2;0.3;1"
          dur="1.075268817204301s"
          repeatCount="indefinite"
          begin="-0.1075268817204301s"
        />
        <animate
          attributeName="fill"
          values="#97c3ff;#97c3ff;#2f80ed;#97c3ff;#97c3ff"
          repeatCount="indefinite"
          keyTimes="0;0.1;0.2;0.3;1"
          dur="1.075268817204301s"
          begin="-0.1075268817204301s"
        />
      </circle>
      <circle
        cx="72.65247584249852"
        cy="33.542012935810746"
        fill="#97c3ff"
        r="6"
      >
        <animate
          attributeName="r"
          values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996"
          keyTimes="0;0.1;0.2;0.3;1"
          dur="1.075268817204301s"
          repeatCount="indefinite"
          begin="0s"
        />
        <animate
          attributeName="fill"
          values="#97c3ff;#97c3ff;#2f80ed;#97c3ff;#97c3ff"
          repeatCount="indefinite"
          keyTimes="0;0.1;0.2;0.3;1"
          dur="1.075268817204301s"
          begin="0s"
        />
      </circle>
    </svg>
  );
};
