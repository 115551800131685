import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client/core';

const UPDATE_HOLIDAY = gql`
  mutation UpdateHoliday(
    $holidayUid: String!
    $title: String!
    $startDate: ISO8601Date!
    $endDate: ISO8601Date!
  ) {
    updateHoliday(
      holidayUid: $holidayUid
      input: { title: $title, startDate: $startDate, endDate: $endDate }
    ) {
      uid
    }
  }
`;

export const useUpdateHoliday = () => {
  const [updateHolidayMutation] = useMutation(UPDATE_HOLIDAY); // Destructure error from useMutation

  const handleUpdateHoliday = async ({
    holidayUid,
    title,
    startDate,
    endDate,
  }) => {
    try {
      const response = await updateHolidayMutation({
        variables: {
          holidayUid,
          title,
          startDate,
          endDate,
        },
      });

      return response;
    } catch (err) {
      console.error('Error creating holiday:', err);
      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        // Handle GraphQL errors
        return err.graphQLErrors[0]?.message;
      }
      // Handle the error appropriately (e.g., display a user-friendly message)
      return null; // Or throw a custom error for further handling
    }
  };

  return handleUpdateHoliday;
};
